import { Injectable } from '@angular/core';
import { CrosstalkUpdateType, ConversationEvent } from '@ddv/crosstalk';
import { CompareMode, WidgetData, DdvDate, UserDefinedField, DatasetFetchKey, datasetFetchKeysMatch } from '@ddv/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { Datasource, DataWrapper } from '../models/data-source';

@Injectable()
export class WidgetDataSourceService {
    public readonly dataSource$: Observable<Datasource>;
    public readonly dataSourcesNames$: Observable<{ [key: number | string]: string }>;

    private dataSourceValue: Datasource = { datasources: [], lastChangedDataset: undefined };
    private readonly dataSource: Subject<Datasource> = new ReplaySubject(1);
    private readonly dataSourcesNames: Subject<{ [key: number]: string }> = new ReplaySubject(1);

    constructor() {
        this.dataSource$ = this.dataSource.asObservable();
        this.dataSourcesNames$ = this.dataSourcesNames.asObservable();
        this.dataSource.next(this.dataSourceValue);
        this.dataSourcesNames.next({});
    }

    addDataSource(newData: DataWrapper): void {
        this.formatBlankAndDateFields(newData.data);

        this.dataSource
            .pipe(first())
            .subscribe({
                next: (res: Datasource) => {
                    const existingData = res.datasources.find((data) => datasetFetchKeysMatch(data.uniqueKey, newData.uniqueKey));
                    if (!existingData) {
                        res.datasources.push(newData);
                    } else {
                        if (newData.compareMode === CompareMode.COMPARED) {
                            existingData.compareData = newData.compareData;
                        } else {
                            existingData.data = newData.data;
                        }
                        existingData.compareMode = newData.compareMode;
                    }

                    this.dataSourceValue = { datasources: [...res.datasources], lastChangedDataset: newData.uniqueKey };
                    this.dataSource.next(this.dataSourceValue);
                },
            });
    }

    removeDataSource(uniqueKey: DatasetFetchKey, options = { triggerEmit: true }): void {
        this.dataSource
            .pipe(first())
            .subscribe({
                next: (res: Datasource) => {
                    const index = res.datasources.findIndex((data) => datasetFetchKeysMatch(data.uniqueKey, uniqueKey));
                    if (index !== -1) {
                        res.datasources.splice(index, 1);
                    }

                    this.dataSourceValue = { datasources: [...res.datasources], lastChangedDataset: undefined };
                    if (options.triggerEmit) {
                        this.dataSource.next(this.dataSourceValue);
                    }
                },
            });
    }

    restoreDataSource(uniqueKey: DatasetFetchKey): void {
        this.dataSource
            .pipe(first())
            .subscribe({
                next: (res: Datasource) => {
                    const existingData = res.datasources.find((data) => datasetFetchKeysMatch(data.uniqueKey, uniqueKey));
                    if (existingData) {
                        existingData.compareData = undefined;
                        existingData.compareMode = undefined;
                        this.dataSourceValue = { datasources: [...res.datasources], lastChangedDataset: uniqueKey };
                        this.dataSource.next(this.dataSourceValue);
                    }
                },
            });
    }

    updateDataSourcesNames(newDataSourceName: { [key: number | string]: string }): void {
        this.dataSourcesNames
            .pipe(first())
            .subscribe({
                next: (existingDataSourcesNames) => {
                    const updatedDataSourcesNames = { ...existingDataSourcesNames, ...newDataSourceName };
                    this.dataSourcesNames.next(updatedDataSourcesNames);
                },
            });
    }

    updateDataSource(
        uniqueKey: DatasetFetchKey,
        updateColumn: string,
        updateData: Partial<ConversationEvent> | UserDefinedField,
        updateType: CrosstalkUpdateType,
    ): void {
        const datasource = this.dataSourceValue.datasources.find((source) => datasetFetchKeysMatch(source.uniqueKey, uniqueKey));
        if (updateType === 'userDefinedField') {
            const dataToUpdate =
                datasource?.originalData?.filter((d) => {
                    return (d[updateColumn] as UserDefinedField)?.conversationId === updateData.conversationId;
                }) ??
                datasource?.data?.filter((d) => {
                    return d.conversationId === updateData.conversationId;
                });

            dataToUpdate?.forEach((datum) => {
                datum[updateColumn] = updateData.value;
                datum[`${updateColumn}_commenter`] = updateData.createdBy;
                datum[`${updateColumn}_commentTS`] = updateData.created;
            });
        } else if (updateType === 'comment') {
            const dataToUpdate =
                datasource?.originalData?.filter((d) => d.conversationId === updateData.conversationId) ??
                datasource?.data?.filter((d) => d.conversationId === updateData.conversationId);

            dataToUpdate?.forEach((datum) => {
                datum[updateColumn] = (updateData as ConversationEvent).comment?.message;
                datum[`${updateColumn}Author`] = (updateData as ConversationEvent).comment?.createdBy;
                datum[`${updateColumn}Created`] = (updateData as ConversationEvent).comment?.created;
            });
        }
    }

    getDataSource(uniqueKey: DatasetFetchKey): DataWrapper | undefined {
        return this.dataSourceValue.datasources.find((source) => datasetFetchKeysMatch(source.uniqueKey, uniqueKey));
    }

    private formatBlankAndDateFields(data: WidgetData[]): void {
        const columnsToFormat = new Set();

        data?.forEach((datum) => {
            Object.keys(datum).forEach((key) => {
                if (typeof datum[key] === 'string') {
                    columnsToFormat.add(key);
                }
            });
        });

        data?.forEach((datum) => {
            Object.keys(datum).forEach((key) => {
                const datumIsNull = Object.is(datum[key], null);
                const datumIsEmptyString = typeof datum[key] === 'string' && !(datum[key]).trim();

                if (columnsToFormat.has(key) && (datumIsNull || datumIsEmptyString)) {
                    datum[key] = '';
                }

                if (this.shouldFormatDateField(datum, key)) {
                    datum[key] = this.formatDateField(key, (datum[key] as string).split(' '));
                }
            });
        });
    }

    private formatDateField(period: string, dateArr: string[]): string {
        const month = period === 'Month' ? Number(dateArr[1]) - 1 :
            (period === 'Quarter' ? DdvDate.firstMonthInEachQuarter[dateArr[1]] : 0);
        const date = new DdvDate(Number(dateArr[0]), month, 1);
        return date.toReversePaddedDashFormat();
    }

    private shouldFormatDateField(datum: WidgetData, key: string): boolean {
        return (key === 'Month' || key === 'Quarter' || key === 'Year') && !DdvDate.isStringValidDashFormatDate(datum[key] as string);
    }
}
