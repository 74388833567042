import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { QueryParamsService } from '@ddv/filters';
import { ApiServices, ApiExecutorService, ExecutorService } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    MANAGE_WIDGET_WS_ID,
    WIDGET_LIFECYCLE_EVENT,
    DashboardPreference,
    DatasetDefinitionDetails,
    DatasetFetchRuntimeParams,
    Query,
    FieldMetadata,
    Action,
    ActionState,
    DatasetMetadata,
    MetadataLookup,
    WidgetFilterParams,
    crosstalkFieldMetadatas,
    crosstalkColumns,
    QueryTypeName,
    NamedQuery,
    QueryTemplateHolder,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { ClientsService, FundsService } from '@ddv/reference-data';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { deepCompare, deepClone } from '@ddv/utils';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';

import { DatasetRuntimeParamsBuilderService } from './dataset-runtime-params-builder.service';

interface MetadataQueryResponse {
    actions: Action[];
    metadata: FieldMetadata[];
    supportsDateRange: boolean;
    supportsGranularity: boolean;
}

@Injectable()
export class MetadataService {
    public readonly metadataState: Observable<DatasetMetadata>;
    public readonly actionsState: Observable<ActionState>;

    private readonly metadataStateSubject: Subject<DatasetMetadata> = new BehaviorSubject<DatasetMetadata>({ metadata: new Map() });
    private clientCode = '';
    private isMultiClient = false;
    private dashboardClientsList: string[] = [];
    private manageWidgetClientsList: string[] = [];
    private fullClientsList = '';
    private dashboardQueryParams: DashboardPreference | undefined;
    private readonly metadataDetails: Map<number, MetadataDetails> = new Map();
    private readonly actionsStateSubject: Subject<ActionState> = new BehaviorSubject({});

    constructor(
        private readonly currentStateService: CurrentStateService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly fundsService: FundsService,
        private readonly usageTracking: UsageTrackingService,
        private readonly managerService: ManagerService,
        private readonly queryParamsService: QueryParamsService,
        private readonly clientsService: ClientsService,
        private readonly namedQueriesService: NamedQueriesService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
        @Inject(ApiServices.nqs) private readonly nqsApiExecutor: ApiExecutorService,
        private readonly executor: ExecutorService,
        private readonly runtimeParamsBuilderService: DatasetRuntimeParamsBuilderService,
    ) {
        this.currentStateService.clientCode$.subscribe({
            next: (clientCode) => this.clientCode = clientCode,
        });
        this.metadataState = this.metadataStateSubject.asObservable();
        this.actionsState = this.actionsStateSubject.asObservable();

        this.currentStateService.isMultiClient$.pipe(switchMap((isMultiClient) => {
            this.isMultiClient = isMultiClient;
            if (this.isMultiClient) {
                return combineLatest([
                    this.queryParamsService.dashboardQueryParams,
                    this.queryParamsService.widgetQueryParams,
                    this.clientsService.clients(),
                ]);
            } else {
                return combineLatest([of({}), of({}), this.fundsService.funds()]);
            }
        })).subscribe({
            next: ([dashboardQueryParams, widgetQueryParams, list]) => {
                if (this.isMultiClient) {
                    if (dashboardQueryParams && Object.keys(dashboardQueryParams).length) {
                        this.dashboardClientsList = (dashboardQueryParams as DashboardPreference).clients?.map((cl) => cl.clientId) ?? [];
                    }
                    if (widgetQueryParams && Object.keys(widgetQueryParams).length) {
                        (widgetQueryParams as WidgetFilterParams).widgetFilters.forEach((widgetFilters, _) => {
                            if (widgetFilters.clients) {
                                this.manageWidgetClientsList = widgetFilters.clients.map((client) => client.clientId);
                            }
                        });
                    }
                    this.fullClientsList = list.join(',');
                }
            },
        });

        this.queryParamsService.dashboardQueryParams.subscribe({
            next: (dashboardQueryParams) => this.dashboardQueryParams = dashboardQueryParams,
        });
    }

    // this is called only by ApplicationBaseWidget.fetchMetaData
    getMetadata(context: BoardWidgetDefinitionIds): void {
        this.fetchMetadataUniqueMetadata(context).subscribe({
            next: (metadata: MetadataLookup) => {
                Object.entries(metadata).forEach(([key, value]) => {
                    value.name = key;
                });

                if (context.widgetId) {
                    this.addMetadataToState(context.widgetId, metadata);
                }
            },
        });
    }

    addMetadataToState(widgetId: number, metadata: MetadataLookup): void {
        const currentState = (this.metadataStateSubject as BehaviorSubject<DatasetMetadata>).value;
        if (!deepCompare(currentState.metadata.get(widgetId), metadata)) {
            const newMetadata = deepClone(currentState.metadata);
            newMetadata.set(widgetId, deepClone(metadata));

            const newState: DatasetMetadata = { ...currentState, lastChangedWidgetId: widgetId, metadata: newMetadata };
            this.metadataStateSubject.next(newState);
        }
    }

    removeMetadata(widgetId: number): void {
        const currentState = (this.metadataStateSubject as BehaviorSubject<DatasetMetadata>).value;
        const newMetadata: Map<number, MetadataLookup> = new Map();
        // eslint-disable-next-line guard-for-in
        for (const key in currentState.metadata) {
            const keyNumber = Number(key);
            if (keyNumber !== widgetId) {
                const metadata = currentState.metadata.get(keyNumber);
                if (metadata) {
                    newMetadata.set(keyNumber,metadata);
                }
            }
        }

        if (this.metadataDetails.get(widgetId)) {
            this.metadataDetails.delete(widgetId);
        }

        const newState: DatasetMetadata = { ...currentState, lastChangedWidgetId: undefined, metadata: newMetadata };
        this.metadataStateSubject.next(newState);
    }

    fetchMetadataUniqueMetadata(context: BoardWidgetDefinitionIds): Observable<MetadataLookup> {
        const widgetId = context.widgetId;
        if (!widgetId) {
            return throwError(() => new Error('cannot fetch metadata without a widget id'));
        }

        return this.fetchAndMapMetadataForDatasetDefinition(context)
            .pipe(map((metadata: MetadataLookup) => {
                const details = this.metadataDetails.get(widgetId);
                if (details) {
                    details.metadata = of(metadata);
                }
                return metadata;
            }));
    }

    fetchMetadataAllMetadata(contexts: BoardWidgetDefinitionIds[]): Observable<Record<number, MetadataLookup>> {
        const fetchObservables = contexts.map((context) => {
            return this.fetchAndMapMetadataForDatasetDefinition(context);
        });

        return forkJoin(fetchObservables)
            .pipe(map((metadatas: MetadataLookup[]) => {
                return metadatas.reduce((hash, metadata, index): Record<number, MetadataLookup> => {
                    const widgetId = contexts[index].widgetId;
                    if (!widgetId) {
                        return hash;
                    }

                    const details = this.metadataDetails.get(widgetId);
                    if (details) {
                        details.metadata = of(metadata);
                    }
                    hash[widgetId] = metadata;
                    return hash;
                }, {} as Record<number, MetadataLookup>);
            }));
    }

    private fetchAndMapMetadataForDatasetDefinition(context: BoardWidgetDefinitionIds): Observable<MetadataLookup> {
        if (!context.definitionId) {
            return throwError(() => new Error('cannot fetch metadata for definition with a definition id'));
        }

        const details$: Observable<NamedQuery | DatasetDefinitionDetails> =
            typeof context.definitionId === 'string' ?
                // Only the code in this if should remain
                // once we switch to named queries entirely
                this.namedQueriesService.fetchNamedQuery(context.definitionId) :
                this.datasetDefinitionsService.fetchDatasetDefinitionDetails(context.definitionId);

        return details$.pipe(mergeMap((datasetDefinition: DatasetDefinitionDetails | NamedQuery) => {
            return this.getMetadataDetails(datasetDefinition, context);
        }));
    }

    private getMetadataDetails(
        datasetDefinition: DatasetDefinitionDetails | NamedQuery,
        context: BoardWidgetDefinitionIds,
    ): Observable<MetadataLookup> {
        return this.getQueryParams(datasetDefinition, context).pipe(switchMap((params): Observable<MetadataLookup> => {
            const widgetId = context.widgetId;
            let query: Query;
            try {
                query = datasetDefinition.populateQueryTemplate(params);
                const conversableType = datasetDefinition instanceof DatasetDefinitionDetails ?
                    datasetDefinition.conversableType :
                    datasetDefinition?.crosstalkOptions?.conversableType;
                if (conversableType) {
                    query.conversationType = conversableType;
                    if (query.selectedColumns) {
                        query.selectedColumns = [...query.selectedColumns, ...crosstalkColumns];
                    }
                }
            } catch (error) {
                const message = {
                    action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                    exception: { message: (error as Error).message },
                };

                if (widgetId) {
                    this.managerService.sendMessageToExistingWidget(widgetId, message);
                }

                return throwError(() => error);
            }

            const dashboardId = this.managerService.getCurrentDashboardId();
            if (!dashboardId || !widgetId) {
                return throwError(() => new Error('cannot getMetadataDetails without a dashboardId and a widgetId'));
            }

            if (this.shouldFetchMetadata(datasetDefinition, dashboardId, widgetId)) {
                const usage: UsageTracker = this.usageTracking.startTracking('DDV.Dataset.MetaData', context.toRecord());
                const clientCodeAndRoute = this.getClientCodeAndRoute(datasetDefinition);
                if (this.isMultiClient) {
                    clientCodeAndRoute.route += `?clients=${dashboardId === MANAGE_WIDGET_WS_ID ?
                        (this.manageWidgetClientsList.length ? this.manageWidgetClientsList.join(',') : this.fullClientsList) :
                        (this.dashboardClientsList.length ? this.dashboardClientsList.join(',') : this.fullClientsList)}`;
                }

                const metadataRequest$: Observable<MetadataLookup> = this.fetchMetadata(
                    widgetId,
                    datasetDefinition,
                    clientCodeAndRoute,
                    query,
                    usage);

                const metadataDetails = {
                    dsd: datasetDefinition,
                    metadata: metadataRequest$,
                    clients: this.isMultiClient ?
                        dashboardId === MANAGE_WIDGET_WS_ID ?
                            this.manageWidgetClientsList :
                            this.dashboardClientsList :
                        [],
                };
                this.metadataDetails.set(widgetId, metadataDetails);
            }

            const lookup = this.metadataDetails.get(widgetId)?.metadata;
            if (!lookup) {
                return throwError(() => new Error(`metadataDetails map does not have ${widgetId}`));
            }
            return lookup;
        }));
    }

    private fetchMetadata(
        widgetId: number,
        datasetDefinition: DatasetDefinitionDetails | NamedQuery,
        clientCodeAndRoute: { clientCode: string, route: string },
        query: Query,
        usage: UsageTracker,
    ): Observable<MetadataLookup> {
        const queryType = datasetDefinition instanceof DatasetDefinitionDetails ?
            datasetDefinition.queryType.name :
            datasetDefinition?.type.name;

        const metadata$: Observable<MetadataQueryResponse> = this.getMetadataObservable(
            datasetDefinition,
            queryType,
            clientCodeAndRoute,
            query,
            usage,
        );

        return metadata$.pipe(
            map((response: MetadataQueryResponse): MetadataLookup => {
                this.updateActionsState(datasetDefinition.id, response.actions);
                usage.succeeded();

                response.metadata = this.mapUdfNumberToDecimal(response.metadata);

                const conversableType = datasetDefinition instanceof DatasetDefinitionDetails ?
                    datasetDefinition.conversableType :
                    datasetDefinition?.crosstalkOptions?.conversableType;
                if (conversableType) {
                    response.metadata.push(...crosstalkFieldMetadatas);
                }
                return response.metadata.reduce((hash: MetadataLookup, row: FieldMetadata) => {
                    if (row.name) {
                        hash[row.name] = row;
                    }
                    return hash;
                }, {});
            }),
            catchError((error: HttpErrorResponse) => {
                const response = error;
                usage.failed(response.error || response.message);
                console.error(response);
                const message = {
                    action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                    exception: { message: (response.error || response.message) },
                };

                this.managerService.sendMessageToExistingWidget(widgetId, message);
                if (response.url?.indexOf('tfl') !== -1 && response.status === 403) {
                    Object.assign(error, { message: 'You are not entitled to this action' });
                }
                return throwError(() => error);
            }),
        );
    }

    private getMetadataObservable(
        datasetDefinition: DatasetDefinitionDetails | NamedQuery,
        queryType: string,
        clientCodeAndRoute: { clientCode: string, route: string },
        query: Query,
        usage: UsageTracker,
    ): Observable<MetadataQueryResponse> {
        let metadata$: Observable<MetadataQueryResponse>;
        const method = 'POST';
        if (requestMetadataFromNQS(queryType)) {
            if (datasetDefinition instanceof DatasetDefinitionDetails) {
                metadata$ = this.nqsApiExecutor.invokeServiceWithBody<MetadataQueryResponse>(
                    clientCodeAndRoute.clientCode,
                    clientCodeAndRoute.route,
                    method,
                    query,
                    { usageTracker: usage },
                );
            } else {
                metadata$ = this.executor.invokeServiceWithBody<MetadataQueryResponse>(
                    clientCodeAndRoute.route,
                    method,
                    query,
                    { usageTracker: usage },
                );
            }
        } else {
            metadata$ = this.trebekApiExecutor.invokeServiceWithBody<MetadataQueryResponse>(
                clientCodeAndRoute.clientCode,
                clientCodeAndRoute.route,
                method,
                query,
                { usageTracker: usage },
            );
        }
        return metadata$;
    }

    private getQueryParams(
        namedQuery: QueryTemplateHolder,
        context: BoardWidgetDefinitionIds,
    ): Observable<DatasetFetchRuntimeParams> {
        return combineLatest([this.queryParamsService.widgetQueryParams, this.managerService.activeWidgets])
            .pipe(skipWhile(([, widgets]) => !widgets.some((w) => w.id === context.widgetId)))
            .pipe(skipWhile(([widgetQueryParams, widgets]) => {
                return !widgets.find((w) => w.id === context.widgetId)?.getExtraPreferences()?.isSubscribedToDashboardFilters &&
                    (!!context.widgetId && !widgetQueryParams?.widgetFilters?.has(context.widgetId));
            }))
            .pipe(take(1))
            .pipe(map(([widgetQueryParams, widgets]) => {
                const widget = widgets.find((w) => w.id === context.widgetId)?.getExtraPreferences();
                const widgetQueryParam = widget?.id ? widgetQueryParams?.widgetFilters.get(widget?.id) : undefined;
                const widgetComparing = widgetQueryParam?.comparing;
                const dashboardComparing = this.dashboardQueryParams?.comparing;
                const mode = widgetComparing ?? dashboardComparing;
                const inCompareMode = widgetQueryParam?.isComparing ?? this.dashboardQueryParams?.isComparing;
                const preference = widget?.isSubscribedToDashboardFilters && !inCompareMode ? this.dashboardQueryParams : widgetQueryParam;

                return this.runtimeParamsBuilderService.build(
                    namedQuery.normalizeQueryTypeName(),
                    mode,
                    preference,
                    this.isMultiClient);
            }));
    }

    private shouldFetchMetadata(
        datasetDefinition: DatasetDefinitionDetails | NamedQuery,
        dashboardId: string,
        widgetId: number,
    ): boolean {
        const metadataDetails = this.metadataDetails.get(widgetId);
        return !metadataDetails || metadataDetails && !deepCompare(metadataDetails.dsd, datasetDefinition) ||
            (this.isMultiClient &&
            ((dashboardId !== MANAGE_WIDGET_WS_ID && !deepCompare(metadataDetails.clients, this.dashboardClientsList)) ||
            (dashboardId === MANAGE_WIDGET_WS_ID && !deepCompare(metadataDetails.clients, this.manageWidgetClientsList))));
    }

    private getClientCodeAndRoute(datasetDefinition: DatasetDefinitionDetails | NamedQuery): { clientCode: string, route: string } {
        const metadataEndpoint = datasetDefinition instanceof DatasetDefinitionDetails ?
            datasetDefinition.metadataEndpoint(this.clientCode) :
            datasetDefinition.type.metadataEndpoint;
        if (datasetDefinition instanceof DatasetDefinitionDetails) {
            return splitEndpointIntoClientAndRoute(metadataEndpoint);
        // when fetching metadata for a named query from NQS we don't want to split the route and client
        // we want to use the full route
        } else if (requestMetadataFromNQS(datasetDefinition.type.name)) {
            return { clientCode: '', route: metadataEndpoint };
        }
        return splitFullNQSURLIntoClientAndRoute(metadataEndpoint);
    }

    private updateActionsState(datasetId: number | string, actions: Action[]): void {
        const currentActionState = (this.actionsStateSubject as BehaviorSubject<ActionState>).value;
        const updatedActionState = { ...currentActionState, [datasetId]: actions };
        this.actionsStateSubject.next(updatedActionState);
    }

    private mapUdfNumberToDecimal(metadata: FieldMetadata[]): FieldMetadata[] {
        metadata.filter((field) => field.name?.toLowerCase().startsWith('udf_') && field.datatype === 'number')
            .forEach((numberField) => numberField.datatype = 'decimal');
        return metadata;
    }
}

interface MetadataDetails {
    dsd: DatasetDefinitionDetails | NamedQuery;
    metadata: Observable<MetadataLookup>;
    clients: string[];
}

export function splitEndpointIntoClientAndRoute(endpoint: string): { clientCode: string, route: string } {
    const queryEndpointParts = endpoint.split('/');
    if (queryEndpointParts.shift() === '') {
        queryEndpointParts.shift();
    }
    const clientCode = queryEndpointParts.shift() ?? '';
    const route = queryEndpointParts.join('/');
    return { clientCode, route };
}

export function splitFullNQSURLIntoClientAndRoute(fullURL: string): { clientCode: string, route: string } {
    const url = new URL(fullURL);
    const endpointParts = url.pathname.split('/');
    const clientCode = endpointParts[3];
    const route = endpointParts.slice(4).join('/');
    return { clientCode, route };
}

function requestMetadataFromNQS(queryTypeName: string): boolean {
    return queryTypeName === QueryTypeName.CASH_PROJECTION ||
        queryTypeName === QueryTypeName.ECDI ||
        queryTypeName === QueryTypeName.INSTRUMENT ||
        queryTypeName === QueryTypeName.INVESTORS_ACTIVITY ||
        queryTypeName === QueryTypeName.NET_SETTLEMENT_DETAILS ||
        queryTypeName === QueryTypeName.PORTFOLIO ||
        queryTypeName === QueryTypeName.POSITIONS ||
        queryTypeName === QueryTypeName.RECON ||
        queryTypeName === QueryTypeName.SETTLEMENT_BREAKS ||
        queryTypeName === QueryTypeName.TAX ||
        queryTypeName === QueryTypeName.TRADES ||
        queryTypeName === QueryTypeName.TRANSACTIONS;
}
