import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Action, ActionName, isCellLevelEditActionHandler, LegacyAction } from '@ddv/models';
import { removeDuplicates } from '@ddv/utils';

interface IconDescriptor {
    iconName: string;
    actionName: string;
    displayName: string;
}

@Component({
    selector: 'ddv-actions-handler',
    templateUrl: './actions-handler.component.html',
    styleUrls: ['./actions-handler.component.scss'],
})
export class ActionsHandlerComponent implements OnChanges {
    @Input() actions: (Action | LegacyAction)[] | undefined;
    @Input() filterName: string | undefined;
    @Input() filterActive = false;

    @Output() actionPressed: EventEmitter<string> = new EventEmitter();
    @Output() filterActiveChange: EventEmitter<boolean> = new EventEmitter();

    protected iconDescriptors: IconDescriptor[] = [];
    private readonly actionsWithIcons = [ActionName.Release, ActionName.Edit];

    ngOnChanges(): void {
        this.buildActions();
    }

    private buildActions(): void {
        if (!this.actions) {
            return;
        }

        this.actions = this.actions.filter((action: Action | LegacyAction) => {
            let shouldSubmitInBatch = true;
            if (isCellLevelEditActionHandler(action.handler)) {
                shouldSubmitInBatch = action.handler.submitInBatch;
            }

            return this.actionsWithIcons.includes(action.name) && shouldSubmitInBatch;
        });

        const actionNames = removeDuplicates(this.actions.map((action: Action | LegacyAction) => action.name));

        this.iconDescriptors = actionNames.map((actionName: ActionName) => {
            return actionNameToIconDescriptor(actionName);
        }) ?? [];
    }
}

function actionNameToIconDescriptor(actionName: ActionName): IconDescriptor {
    switch (actionName) {
        case ActionName.Release:
            return {
                iconName: 'send',
                displayName: actionName,
                actionName,
            };
        case ActionName.Edit:
            return {
                iconName: 'push',
                displayName: 'Submit Edits',
                actionName,
            };
        default:
            console.error('Unknown action name');
            return {
                iconName: '',
                actionName: '',
                displayName: '',
            };
    }
}
