export interface LegacyAction {
    name: ActionName.Reprocess | ActionName.Acknowledge;
    type: 'each';
    handler: {
        method: 'POST' | 'PUT' | 'DELETE';
        url: string;
        body: LegacyActionHandlerBody;
    };
}

export interface LegacyActionHandlerBody {
    type: 'array' | 'object' | 'wrapped-array';
    contentType?: string;
    wrapperPropertyName?: string;
    keyField?: string;
    items?: {
        type?: 'object';
        properties: Record<string, string>;
    };
    properties?: {
        [key: string]: unknown;
    };
}

// Action naming convention:
//  - *propertyName means this will be the name of a property that ends up in the payload sent to the backend
//  - *fieldName means this is the name of the property on the row for getting values to put into the payload
//  - *wrapperPropertyName means this will wrap the payload in an object with this property name

export interface Action {
    name: ActionName;
    type: 'cell-level-edit' | 'each';
    handler: CellLevelEditActionHandler | RowLevelEditActionHandler;
}

interface RowLevelEditActionHandler {
    method: 'POST' | 'PUT' | 'DELETE';
    url: string;
    body: ActionObjectBody | ActionEntityBody | ActionArrayBody | ActionWrappedArrayBody;
}

export interface ActionObjectBody {
    type: 'object';
    wrapperPropertyName: string;
    rowKeyFieldName: string;
    rowValuePropertyName: string;
    rowValueFieldName: string;
}

interface ActionEntityBody {
    type: 'entity';
    keyPropertyName: string;
    keyFieldName: string;
    valuePropertyName: string;
    valueFieldName: string;
}

interface ActionArrayBody {
    type: 'array';
    rowFieldName: string;
}

export interface ActionWrappedArrayBody {
    type: 'wrapped-array';
    wrapperPropertyName: string;
    rowFieldName: string;
}

export interface CellLevelEditActionHandler {
    fieldName: string;
    method: 'POST' | 'PUT' | 'DELETE';
    url: string;
    body: ActionObjectBody | ActionEntityBody;
    submitInBatch: boolean;
}

export interface ActionState {
    [key: number | string]: (Action | LegacyAction)[];
}

export enum ActionName {
    Acknowledge = 'acknowledge',
    Edit = 'edit',
    Release = 'release',
    Reprocess = 'reprocess',
}

export interface AcknowledgeActionResponse {
    success: boolean;
}

interface ReprocessActionResponse {
    success: boolean;
}

interface EditActionResponseBody {
    saved_fields: string[];
    status: string;
    status_msg: string;
}

interface EditActionResponse {
    trades: Record<string, EditActionResponseBody>;
}

interface ReleaseActionBodyResponse {
    status: string;
    message?: string;
}

export interface BatchAction {
    startValue: string;
    latestValue: string;
    method: 'POST' | 'PUT' | 'DELETE';
    url: string;
    payload: Record<string, unknown>;
}

type ReleaseActionResponse = Record<string, ReleaseActionBodyResponse>;

export type ActionResponse = AcknowledgeActionResponse | ReprocessActionResponse | EditActionResponse | ReleaseActionResponse;

export const actionsWithPartialUrls = [ActionName.Acknowledge, ActionName.Reprocess];

export function isActionObjectBody(value: unknown): value is ActionObjectBody {
    return !!value && (value as ActionObjectBody).type === 'object' && Object.keys(value as ActionObjectBody).length === 5;
}

export function isActionWrappedArrayBody(value: unknown): value is ActionWrappedArrayBody {
    return !!value && (value as ActionWrappedArrayBody).type === 'wrapped-array' && Object.keys(value as ActionWrappedArrayBody).length === 3;
}

export function isLegacyAction(value: unknown): value is LegacyAction {
    return !!value && [ActionName.Reprocess, ActionName.Acknowledge].includes((value as LegacyAction).name);
}

export function isCellLevelEditActionHandler(value: unknown): value is CellLevelEditActionHandler {
    return !!value && !!(value as CellLevelEditActionHandler).fieldName && Object.keys(value as CellLevelEditActionHandler).length === 5;
}
