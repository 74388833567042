import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardGroup, DashboardService, DdvWidgetService } from '@ddv/dashboards';
import { CompareModeService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { Workspace, ManagerService } from '@ddv/layout';
import {
    TMode,
    Client,
    MODE,
    CompareMode,
    DashboardModel,
    DashboardSnapshot,
    DashboardPreference,
    DatasetDefinitionDetails,
    QueryPeriodTypeName,
    QueryTypeName,
    UserPreferences,
    ExportInfo,
    Fund,
    AppWidgetState,
    WIDGET_LIFECYCLE_EVENT,
    FilterQueryParam,
} from '@ddv/models';
import { ClientsService, FundsService, FuzzyDatesService } from '@ddv/reference-data';
import { deepCompare, deepClone } from '@ddv/utils';
import { combineLatest } from 'rxjs';

// this component is NOT a dashboard at all
// its just the "top bar" of the dashboard that includes the name, the query params, etc
@Component({
    selector: 'app-hs-dashboard',
    templateUrl: './hs-dashboard.component.html',
    styleUrls: ['./hs-dashboard.component.scss'],
})
export class HsDashboardComponent extends MultiSubscriptionComponent implements OnChanges, OnInit, OnDestroy {
    @Input() currentDashboard: Workspace | undefined;
    @Input() inPresentationMode = false;
    @Input() dashboardGroups: DashboardGroup[] | undefined;
    @Input() dashboardSnapshots: DashboardSnapshot[] | undefined;
    @Input() currentActiveDate: string | null | undefined;

    @Output() cancelEdit = new EventEmitter();
    @Output() revertChanges = new EventEmitter();
    @Output() saveDashboardWidgets = new EventEmitter<DashboardPreference>();
    @Output() exportOptionsClose = new EventEmitter<ExportInfo>();
    @Output() dashboardSwitch = new EventEmitter<string>();

    isLastDashboardClosed = false;
    queryParams: DashboardPreference | undefined;
    showHighlight = true;
    showSettings = false;
    clientCode = '';
    isMultiClient = false;
    userSelectedDate: { dateFrom: string, dateTo: string } | undefined;

    private fundOptions: Fund[] = [];
    private clientOptions: Client[] = [];
    private userPreference: UserPreferences | undefined;
    private dashboardParamsChangedOnView = false;
    private detailWidgetId: number | undefined;

    constructor(
        private readonly currentStateService: CurrentStateService,
        private readonly dashboardService: DashboardService,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly fundService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly manager: ManagerService,
        private readonly userService: UserService,
        private readonly queryParamsService: QueryParamsService,
        private readonly compareModeService: CompareModeService,
        private readonly ddvWidgetService: DdvWidgetService,
    ) {
        super();
    }

    private static isViewAndSavedQueryParamsEqual(viewPreference?: DashboardPreference, savedPreference?: DashboardPreference): boolean {
        return (!!viewPreference &&
            !!savedPreference &&
            viewPreference.startDate === savedPreference.startDate &&
            viewPreference.endDate === savedPreference.endDate &&
            viewPreference.activeDate === savedPreference.activeDate &&
            deepCompare(viewPreference.filters, savedPreference.filters) &&
            deepCompare(viewPreference.funds, savedPreference.funds)) &&
            deepCompare(viewPreference.clients, savedPreference.clients);
    }

    ngOnInit(): void {
        this.subscribeTo(this.userService.userPreferences$, (userPref: UserPreferences) => {
            this.userPreference = userPref;
            this.userSelectedDate = { dateFrom: this.userPreference.startDate ?? '', dateTo: this.userPreference.endDate ?? '' };
        });

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.queryParams = dashboardPref;
        });

        this.subscribeTo(combineLatest([this.currentStateService.clientCode$, this.currentStateService.isMultiClient$]),
            ([clientCode, isMultiClient]) => {
                this.clientCode = clientCode;
                this.isMultiClient = isMultiClient;

                if (!this.isMultiClient) {
                    this.subscribeTo(this.fundService.funds(), (data: Fund[]) => {
                        if (data) {
                            this.fundOptions = data;
                        }
                    });
                } else {
                    this.subscribeTo(this.clientsService.clients(), (data: string[]) => {
                        if (data) {
                            this.clientOptions = data.map((el) => ({ clientId: el, clientName: el }));
                        }
                    });
                }
            });

        this.subscribeTo(this.ddvWidgetService.detailWidgetId, (detailWidgetId) => {
            this.detailWidgetId = detailWidgetId;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.currentDashboard || this.dirtyFlagService.isDirty) {
            return;
        }

        if (changes.currentDashboard) {
            this.onCurrentDashboardChanges();
        }

        if (changes.inPresentationMode) {
            this.onModeChanges();
        }
    }

    get isHIDataAvailable(): boolean {
        return !!(this.currentDashboard?.extraParameters?.widgets.some((w) => {
            return w.datasetDefinition?.queryType?.name === QueryTypeName.INVESTOR;
        }) && this.fundOptions.some((fund) => fund.availability === 'hi' || fund.availability === 'both'));
    }

    onRestoreCurrentDashboard(): void {
        this.onClearHighlight();
        this.populateDashboardQueryParams(MODE.EDIT_WORKSPACE);

        if (this.isMultiClient && this.detailWidgetId) {
            this.ddvWidgetService.restoreOriginalViewFilters();
        }

        this.compareModeService.disableCompareMode();

        this.manager.sendMessageToAllWidgetsOnWorkspace(0, {
            action: WIDGET_LIFECYCLE_EVENT.VIEW_RESTORED,
        });

        this.dirtyFlagService.exitDirtyState();
    }

    onRefreshCurrentDashboard(): void {
        this.queryParamsService.dispatchNewQueryParams({ ...this.queryParams, isPreferenceChangedOnRefresh: true });
    }

    onUpdateDashboardQueryParams(preference: DashboardPreference): void {
        preference.areFiltersAppliedByMaster = false;
        this.queryParamsService.dispatchUpdatedQueryParams(preference);
        if (!this.inPresentationMode) {
            this.activateDirtyFlagOnParams();
        } else {
            this.dashboardParamsChangedOnView = true;
        }
    }

    onApplySelectedFunds(funds: Fund[]): void {
        this.queryParamsService.dispatchUpdatedQueryParams({
            funds,
            comparing: this.queryParams?.comparing ? CompareMode.BOTH : undefined,
            areFiltersAppliedByMaster: false,
        });
        if (!this.inPresentationMode) {
            this.activateDirtyFlagOnParams();
        } else {
            this.dashboardParamsChangedOnView = true;
        }

        this.updateFuzzyDatesForInvestorDataset(funds);
    }

    onApplySelectedClients(clients: Client[]): void {
        this.queryParamsService.dispatchUpdatedQueryParams({
            clients,
            comparing: this.queryParams?.comparing ? CompareMode.BOTH : undefined,
        });
        if (!this.inPresentationMode) {
            this.activateDirtyFlagOnParams();
        } else {
            this.dashboardParamsChangedOnView = true;
        }
    }

    onApplyDateChanges(preference: FilterQueryParam): void {
        preference.comparing = this.queryParams?.comparing ? CompareMode.ORIGINAL : undefined;
        if (!preference.initialLoad) {
            this.onUpdateDashboardQueryParams(preference);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onDbFiltersApplied(dashboardFilters: any): void {
        this.onUpdateDashboardQueryParams({
            filters: dashboardFilters,
        });
    }

    onClearHighlight(): void {
        this.manager.sendMessageToAllWidgetsOnWorkspace(0, {
            action: 'VISUALIZATION_SELECTED',
            dataSource: [],
            selectedItem: null,
        });
    }

    onCloseDashboardExport(exportInfo: ExportInfo): void {
        this.exportOptionsClose.emit(exportInfo);
    }

    setHighlightVisibility(visible: boolean): void {
        this.showHighlight = visible;
    }

    isQueryTypeRecon(): boolean {
        return !!this.currentDashboard?.extraParameters?.widgets.some((w) => w.datasetDefinition?.queryType?.name === QueryTypeName.RECON);
    }

    private activateDirtyFlagOnParams(): void {
        if (this.isQueryParamChanged(MODE.EDIT_WORKSPACE)) {
            this.dirtyFlagService.enterDirtyState(this.currentDashboard?.id ?? '');
        }
    }

    private isQueryParamChanged(mode: TMode): boolean {
        if (!this.manager.getWorkspace()) {
            return false;
        }

        const dashboardModel: DashboardModel = this.manager.getWorkspace()?.getExtraParameters();
        let storedLastPref = dashboardModel.dashboardPreferences.find((preference) => {
            return preference.dashboardPreferenceMode === mode;
        });

        if (!storedLastPref) {
            storedLastPref = { funds: [], filters: [], clients: [] };
        }

        return !HsDashboardComponent.isViewAndSavedQueryParamsEqual(this.queryParams, storedLastPref);
    }

    private onCurrentDashboardChanges(): void {
        this.populateDashboardQueryParams(MODE.EDIT_WORKSPACE);
    }

    private onModeChanges(): void {
        if (!this.inPresentationMode && this.dashboardParamsChangedOnView) {
            this.queryParamsService.dispatchUpdatedQueryParams({ dashboardPreferenceMode: MODE.EDIT_WORKSPACE });
            this.activateDirtyFlagOnParams();
            this.dashboardParamsChangedOnView = false;
        } else {
            const detailWidgetState = this.manager.getWidgetState(this.detailWidgetId ?? 0);
            if (!detailWidgetState) {
                this.populateDashboardQueryParams();
                if (this.queryParams?.filters?.every((filter) => !filter.isMasterWidgetFilter)) {
                    this.manager.updateWidgetsOnMasterFiltersRemoved();
                }
            }
        }
    }

    private populateDashboardQueryParams(mode?: TMode): void {
        const dashboard = this.manager.getWorkspace();
        if (dashboard) {
            const extraParams = dashboard.getExtraParameters();
            const preferenceMode = mode ?? this.getPreferenceMode(extraParams);
            const params = this.setDashboardQueryParamsPriority(extraParams, preferenceMode);
            this.queryParamsService.dispatchNewQueryParams(params);
        }
    }

    private setDashboardQueryParamsPriority(extraParams: DashboardModel, mode?: TMode): DashboardPreference {
        const preferenceMode: TMode = this.inPresentationMode ? MODE.VIEW : MODE.EDIT_WORKSPACE;
        let dashbPref: DashboardPreference = { funds: [], filters: [], dashboardPreferenceMode: preferenceMode, clients: [] };
        if (!this.inPresentationMode && extraParams?.dashboardPreferences) {
            const dashboardPref = extraParams.dashboardPreferences.find((preference) =>
                preference.dashboardPreferenceMode === MODE.EDIT_WORKSPACE);
            dashbPref = deepClone(dashboardPref) ?? dashbPref;
            dashbPref.funds = dashbPref.funds ?? [];
            dashbPref.clients = dashbPref.clients ?? [];
            dashbPref.acknowledged = !!this.userPreference?.showAcknowledged;
            return dashbPref;
        }

        const newMode = mode ?? MODE.VIEW;
        const workspaceParams = this.manager.getExtraParametersForWorkspace();
        dashbPref = this.dashboardService.getDefaultQueryParams(
            this.fundOptions,
            this.clientOptions,
            workspaceParams);
        const widgetDatasets: DatasetDefinitionDetails[] = workspaceParams?.widgets.map((w: AppWidgetState) => w.datasetDefinition) || [];
        const isCalendarVisible = !widgetDatasets.length ||
            widgetDatasets.some((dsd) => dsd.queryPeriodType?.name !== QueryPeriodTypeName.NO_CALENDAR);
        this.overridePrefWithUserPref(dashbPref);
        this.overridePrefWithSavedParams(dashbPref, extraParams, newMode);
        dashbPref.activeDate = dashbPref.activeDate === undefined ? dashbPref.endDate : dashbPref.activeDate;
        dashbPref.dashboardPreferenceMode = preferenceMode;
        if (!isCalendarVisible) {
            dashbPref.startDate = '';
            dashbPref.endDate = '';
        }
        dashbPref.acknowledged = !!this.userPreference?.showAcknowledged;
        return dashbPref;
    }

    private overridePrefWithUserPref(pref: DashboardPreference): void {
        if (!this.userPreference) {
            return;
        }

        if (this.userPreference.fundsCodes?.length) {
            pref.funds = this.fundOptions.filter((f) => this.userPreference?.fundsCodes.includes(f.fundId));
        }
        if (this.userPreference.clientsCodes?.length) {
            pref.clients = this.clientOptions.filter((c) => this.userPreference?.clientsCodes.includes(c.clientId));
        }
        pref.startDate = this.userPreference.startDate || pref.startDate;
        pref.endDate = this.userPreference.endDate || pref.endDate;
    }

    private overridePrefWithSavedParams(dashbPref: DashboardPreference, extParams: DashboardModel, mode: TMode): void {
        if (extParams?.dashboardPreferences?.length) {
            const savedPref = extParams.dashboardPreferences.find((preference) => preference.dashboardPreferenceMode === mode);
            if (savedPref) {
                if (savedPref.funds?.length) {
                    dashbPref.funds = savedPref.funds;
                }
                if (savedPref.clients?.length) {
                    dashbPref.clients = savedPref.clients;
                }
                dashbPref.startDate = savedPref.startDate || dashbPref.startDate;
                dashbPref.endDate = savedPref.endDate || dashbPref.endDate;
                dashbPref.activeDate = savedPref.activeDate;
                dashbPref.filters = savedPref.filters;
                dashbPref.highlight = savedPref.highlight;
                dashbPref.version = extParams?.getQueryParamVersion?.(); // undefined in tests
            }
        }
    }

    private getPreferenceMode(extParams: DashboardModel): TMode {
        const hasViewMode = extParams.dashboardPreferences.some((preference) =>
            preference.dashboardPreferenceMode === MODE.VIEW,
        );
        return hasViewMode ? MODE.VIEW : MODE.EDIT_WORKSPACE;
    }

    private updateFuzzyDatesForInvestorDataset(funds: Fund[]): void {
        const widgetsOnBoard = this.currentDashboard?.extraParameters?.widgets ?? [];
        if (widgetsOnBoard.length && widgetsOnBoard.every((widget) => widget.datasetDefinition?.dataType === 'Investor')) {
            const updatedFunds = funds.map((fund) => ({ code: fund.fundId }));
            const dsdIds = widgetsOnBoard.map((widget) => widget.datasetDefinition?.id ?? 0);
            this.fuzzyDatesService.pushMostRecentFuzzyDatesForInvestorDataset(updatedFunds, dsdIds).subscribe();
        }
    }
}
