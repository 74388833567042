import { ValueSetterParams } from '@ag-grid-community/core/dist/types/src/entities/colDef';
import { Inject, Injectable } from '@angular/core';
import { ApiExecutorService, ApiServices, ExecutorService } from '@ddv/http';
import { Action, ActionName, ActionResponse, actionsWithPartialUrls, BatchAction, LegacyAction } from '@ddv/models';
import { Observable } from 'rxjs';

import { ActionRequestBody, WrappedObjectPayloadBody } from '../models/action-handler';
import { ActionHandlerBodyBuilderService } from './action-handler-body-builder.service';
import { splitEndpointIntoClientAndRoute } from './metadata.service';

@Injectable()
export class ActionHandlerService {
    private batchChanges: Record<string, Record<string, BatchAction>> = {};

    constructor(
        private readonly bodyBuilder: ActionHandlerBodyBuilderService,
        private readonly executor: ExecutorService,
        @Inject(ApiServices.trebek) private readonly trebekApiService: ApiExecutorService,
    ) {}

    handleAction(
        action: Action | LegacyAction,
        clientCode: string,
        selectedRows: Record<string, unknown>[],
        isTFLIncompleteFiles: boolean,
    ): Observable<ActionResponse> {
        const requestBody = this.bodyBuilder.buildActionHandlerBody(action, selectedRows, isTFLIncompleteFiles);

        if (actionsWithPartialUrls.includes(action.name)) {
            return this.handlePartialUrlAction(action, clientCode, requestBody);
        }

        return this.executor.invokeServiceWithBody(
            escapeURLPlaceHolders(action.handler.url, clientCode),
            action.handler.method,
            requestBody);
    }

    handleBatchAction(clientCode: string, widgetId: number): Observable<ActionResponse>[] {
        const widgetChanges = this.batchChanges[widgetId];
        const requests: Observable<ActionResponse>[] = [];
        let widgetContainsChanges = false;
        for (const key in widgetChanges) {
            if (widgetChanges[key].latestValue === widgetChanges[key].startValue) {
                continue;
            }

            widgetContainsChanges = true;
            const payload = widgetChanges[key].payload;
            const method = widgetChanges[key].method;
            const url = widgetChanges[key].url;

            requests.push(this.executor.invokeServiceWithBody(escapeURLPlaceHolders(url, clientCode), method, payload));
        }

        if (!widgetContainsChanges) {
            throw new Error('No changes to send');
        }

        return requests;
    }

    saveBatchChanges(widgetId: number, event: ValueSetterParams, action: Action): void {
        const actionUrl = action.handler.url;
        const uniqueNodeId = event.column.getId() + event.node?.id;

        this.batchChanges[widgetId] = this.batchChanges[widgetId] ?? {} as Record<ActionName, BatchAction>;
        this.batchChanges[widgetId][uniqueNodeId] = {
            latestValue: event.newValue,
            startValue: this.batchChanges[widgetId][uniqueNodeId]?.startValue ?? event.oldValue,
            url: actionUrl,
            method: action.handler.method,
            payload: this.bodyBuilder.buildActionHandlerBody(action, [event.data], false) as WrappedObjectPayloadBody,
        };
    }

    removeBatchChanges(widgetId: number): void {
        const { [widgetId]: removed , ...batchChanges } = this.batchChanges;
        this.batchChanges = batchChanges;
    }

    private handlePartialUrlAction(
        action: Action | LegacyAction,
        clientCode: string,
        requestBody: ActionRequestBody,
    ): Observable<ActionResponse> {
        const method = action.handler.method;
        const { route } = splitEndpointIntoClientAndRoute(action.handler.url);
        return this.trebekApiService.invokeServiceWithBody(clientCode, route, method, requestBody);
    }
}

function escapeURLPlaceHolders(url: string, clientCode: string): string {
    return url.replace(':clientCode', clientCode);
}
