import { Injectable } from '@angular/core';
import {
    Action,
    LegacyActionHandlerBody,
    ActionName,
    ActionWrappedArrayBody,
    ActionObjectBody,
    isActionWrappedArrayBody,
    isActionObjectBody,
    LegacyAction,
    isLegacyAction,
} from '@ddv/models';

import { ActionRequestBody, ArrayBody, WrappedArrayPayloadBody, WrappedObjectPayloadBody } from '../models/action-handler';

@Injectable()
export class ActionHandlerBodyBuilderService {
    buildActionHandlerBody(
        action: Action | LegacyAction,
        selectedRows: Record<string, unknown>[],
        isTFLIncompleteFiles: boolean,
    ): ActionRequestBody {
        // legacy actions should be adapted to the new format
        if (isLegacyAction(action)) {
            const actionName = action.name;
            if (actionName === ActionName.Reprocess) {
                return buildReprocessPayloadBody(action.handler.body, selectedRows, isTFLIncompleteFiles);
            }
            if (actionName === ActionName.Acknowledge) {
                return buildAcknowledgePayloadBody(action.handler.body, selectedRows, isTFLIncompleteFiles);
            }
        }

        const actionBody = action.handler.body;
        if (isActionWrappedArrayBody(actionBody)) {
            return buildWrappedPayloadBody(actionBody, selectedRows);
        }

        if (isActionObjectBody(actionBody)) {
            return buildObjectPayloadBody(actionBody, selectedRows);
        }

        throw new Error('Unhandled action');
    }
}

function buildReprocessPayloadBody(
    config: LegacyActionHandlerBody,
    selectedRows: Record<string, unknown>[],
    isTFLIncompleteFiles: boolean,
): WrappedObjectPayloadBody | ArrayBody {
    const tradeHistoryIdsSet = new Set();
    selectedRows.forEach((row) => {
        const item: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(config.items!.properties)) {
            item[key] = row[value] || '';
        }
        tradeHistoryIdsSet.add(JSON.stringify(item));
    });

    return isTFLIncompleteFiles ?
        { fileIds: [...tradeHistoryIdsSet] } :
        [...tradeHistoryIdsSet].map((item: unknown) => JSON.parse(String(item)));
}

function buildAcknowledgePayloadBody(
    config: LegacyActionHandlerBody,
    selectedRows: Record<string, unknown>[],
    isTFLIncompleteFiles: boolean,
): WrappedObjectPayloadBody | ArrayBody {
    const tradeHistoryIdsSet = new Set();
    const columnId = config.items!.properties.id;

    selectedRows.forEach((row) => {
        if (isTFLIncompleteFiles) {
            tradeHistoryIdsSet.add(row[columnId]);
        } else {
            tradeHistoryIdsSet.add(JSON.stringify({ id: row[columnId] }));
        }
    });

    return isTFLIncompleteFiles ?
        { fileIds: [...tradeHistoryIdsSet] } :
        [...tradeHistoryIdsSet].map((item: unknown) => JSON.parse(String(item)));
}

// For the function below, the request body that we would build
// would look as follows:
// { wrapperPropertyName : [row1.rowFieldName,  row2.rowFieldName] }

function buildWrappedPayloadBody(config: ActionWrappedArrayBody, selectedRows: Record<string, unknown>[]): WrappedArrayPayloadBody {
    const wrapperPropertyName = config.wrapperPropertyName;
    const rowId = config.rowFieldName;

    return {
        [wrapperPropertyName]: selectedRows.map((row) => row[rowId]),
    };
}

// For the function below, the request body that we would build
// would look as follows:
// {
// wrapperPropertyName :{
//  row1.rowKeyFieldName: { rowValuePropertyName : row1.rowValueFieldName }
//  row2.rowKeyFieldName: { rowValuePropertyName : row2.rowValueFieldName }
//  }
// }

function buildObjectPayloadBody(config: ActionObjectBody, selectedRows: Record<string, unknown>[]): WrappedObjectPayloadBody {
    const { rowValuePropertyName, rowValueFieldName, rowKeyFieldName, wrapperPropertyName } = config;
    const payloadBody: Record<string, Record<string, unknown>> = { [wrapperPropertyName]: {} };

    selectedRows.forEach((row) => {
        const rowWrapper = row[rowKeyFieldName] as string;
        payloadBody[wrapperPropertyName][rowWrapper] = { [rowValuePropertyName]: row[rowValueFieldName] };
    });

    return payloadBody;
}
