import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrentStateService } from '@ddv/behaviors';
import {
    ConfirmationPopupService,
    CustomAutoCompleterComponent,
    CustomAutoCompleteConfig,
    CustomFormValidators,
    ModalDialogActive,
    MultiSubscriptionComponent,
} from '@ddv/common-components';
import { UserEntitlements, UserEntitlementService, UserService } from '@ddv/entitlements';
import { ManagerService } from '@ddv/layout';
import {
    TAG_TYPE,
    UserPermission,
    UserPreferences,
    combineUserAndSharedTags,
    AppWidgetState,
    TWidgetTypes,
    getGlobalSaveDialogOptions,
    NamedQuery,
    DatasetDefinition,
} from '@ddv/models';
import { clone } from '@ddv/utils';
import { Observable } from 'rxjs';

import { WidgetsService } from '../../services/widgets.service';

interface FormData extends AppWidgetState {
    oldName?: string;
}

@Component({
    selector: 'app-save-widget-form',
    templateUrl: 'save-widget-form.component.html',
    styleUrls: ['./save-widget-form.component.scss'],
})
export class SaveWidgetFormComponent extends MultiSubscriptionComponent implements OnInit, AfterViewInit {
    @Output() widgetSaveFormSubmit = new EventEmitter<AppWidgetState>();
    formData: FormData | undefined;
    isCopy = false;
    isSaveInvoked = false;
    isDashboardWidget = false;
    isReadOnly = false;
    isGlobal = false;
    isComparableWidget = false;
    userWidgetPermissions: UserPermission[] | undefined;
    saveWidgetForm: UntypedFormGroup | undefined;
    tagsConfiguration: CustomAutoCompleteConfig | undefined;
    coreWidgetType: TWidgetTypes | undefined;
    userPreferences: UserPreferences | undefined;
    widgetQueryTypeName: string | undefined;
    widgetQueryName: string | undefined;
    isHSUser = false;
    isMultiClient = false;
    isStackedQuery = false;
    disableEditGlobalWidget = false;
    namedQuery: NamedQuery | undefined;

    @ViewChildren(CustomAutoCompleterComponent) private readonly autoCompleterRefs: QueryList<CustomAutoCompleterComponent> | undefined;
    private userTagCompleterRef: CustomAutoCompleterComponent | undefined;
    private sharedTagCompleterRef: CustomAutoCompleterComponent | undefined;
    private isGlobalEditPartial = false;

    constructor(
        public modalDialogActive: ModalDialogActive,
        private readonly fb: UntypedFormBuilder,
        private readonly widgetsService: WidgetsService,
        private readonly userEntitlementsService: UserEntitlementService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly userService: UserService,
        private readonly managerService: ManagerService,
        private readonly currentStateService: CurrentStateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initForm(clone({
            id: null,
            name: '',
            displayNameType: 'CONFIGURED',
            customDisplayName: '',
            enableWidgetHeader: true,
            description: '',
            isSubscribedToDashboardFilters: true,
            enableAutoRefresh: true,
            enableCompareMode: true,
            isLinkedToMaster: true,
            isShared: false,
            isSelectable: true,
            widgetTags: [],
        }, this.formData));

        if (this.formData) {
            this.isGlobal = !!this.formData.isGlobal;

            if (this.formData.id) {
                this.markFormGroupTouched();
                if (this.isDashboardWidget) {
                    this.subscribeTo(this.userEntitlementsService.userData$, (userData) => {
                        this.isHSUser = userData.internal;
                        if (this.isHSUser) {
                            const widgetOnBoardId = this.formData?.id ?? 0;
                            const widget = this.managerService.getWidgetPreferences(widgetOnBoardId);
                            const { queryName, queryTypeName } = this.determineWidgetQueryNameAndQueryTypeName(widget?.datasetDefinition);
                            this.widgetQueryName = queryName;
                            this.widgetQueryTypeName = queryTypeName;
                        }
                    });
                }
            } else if (this.formData.name) {
                this.saveWidgetForm?.get('name')?.markAsTouched();
            }

            this.formData.widgetTags = this.formData.widgetTags ?? [];
            this.validateDuplicate();
            this.tagsConfiguration = {
                placeholderText: 'Enter Tag',
                suggestionText: 'Suggested Tags',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getSourceFn: (term): Observable<any[]> => this.widgetsService.fetchWidgetTags(term, this.formData?.clientCode ?? ''),
            };
        }

        this.subscribeTo(this.userEntitlementsService.entitlementsForClientCode$, (entitlements: UserEntitlements) => {
            this.isGlobalEditPartial = entitlements.haveGlobalEditPartial;
            this.disableEditGlobalWidget = this.isGlobalEditPartial && this.isGlobal;
        });

        this.subscribeTo(this.userService.userPreferences$, (userPreferences: UserPreferences) => {
            this.userPreferences = userPreferences;
        });

        this.subscribeTo(this.currentStateService.isMultiClient$, (isMultiClient: boolean) => {
            this.isMultiClient = isMultiClient;
        });
    }

    ngAfterViewInit(): void {
        this.userTagCompleterRef = this.autoCompleterRefs?.first;
        this.sharedTagCompleterRef = this.autoCompleterRefs?.last;

        if (this.sharedTagCompleterRef) {
            this.sharedTagCompleterRef.isReadOnly = this.isDashboardWidget;
            this.sharedTagCompleterRef.isDisabled = this.isDashboardWidget;
        }

        if (this.formData?.id) {
            this.formData.widgetTags?.forEach((tag) => {
                if (tag.type === TAG_TYPE.SHARED) {
                    this.sharedTagCompleterRef?.selectedList.push(tag);
                }
                if (tag.type === TAG_TYPE.USER) {
                    this.userTagCompleterRef?.selectedList.push(tag);
                }
            });
        }
    }

    saveWidgetInfo(data: FormData): void {
        if (this.isGlobal && !this.isGlobalEditPartial) {
            const confirmDialogOptions = getGlobalSaveDialogOptions();
            this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
                next: (action) => {
                    if (action === 'confirm') {
                        this.onWidgetInfoSave(data);
                    }
                },
            });
        } else {
            this.onWidgetInfoSave(data);
        }
    }

    onWidgetInfoSave(data: FormData): void {
        if (!data.id || data.oldName !== data.name) {
            this.widgetsService.validateWidgetName(data.name ?? '').subscribe((result) => {
                if (result) {
                    this.invokeWidgetSave(result, data);
                } else {
                    this.saveWidgetForm?.get('name')?.setErrors({ isDuplicate: true });
                }
            });
        } else {
            this.invokeWidgetSave(true, data);
        }
    }

    validateDuplicate(): void {
        const formNameCtrl = this.saveWidgetForm?.get('name');
        const formGroupValue = this.saveWidgetForm?.getRawValue();
        if (formNameCtrl?.valid && (!formGroupValue.id || formGroupValue.oldName !== formGroupValue.name)) {
            this.widgetsService.validateWidgetName(formGroupValue.name)
                .subscribe((result) => {
                    if (result) {
                        formNameCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                    } else {
                        formNameCtrl.setErrors({ isDuplicate: true });
                    }
                });
        }
    }

    isSaveDisabled(): boolean {
        const userPermission = this.userWidgetPermissions?.some((permission) => {
            return permission.privilege === 'EDIT' &&
                permission.name.toLocaleLowerCase() === this.userPreferences?.username.toLocaleLowerCase();
        });

        return !this.saveWidgetForm?.valid || this.isSaveInvoked
            || (this.formData?.privilege === 'VIEW' && !this.isDashboardWidget && !this.isCopy && !userPermission);
    }

    isAutoOptionDisabled(): boolean | null {
        return (this.isReadOnly || this.coreWidgetType === 'SUMMARY') ? true : null;
    }

    private invokeWidgetSave(validName: boolean, data: FormData): void {
        if (validName) {
            data.widgetTags = combineUserAndSharedTags(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.userTagCompleterRef?.selectedList as any,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.sharedTagCompleterRef?.selectedList as any);
            data.widgetPermissions = data.isShared ? this.userWidgetPermissions : [];
            this.isSaveInvoked = true;
            this.widgetSaveFormSubmit.emit(data);
        } else {
            this.saveWidgetForm?.get('name')?.setErrors({ isDuplicate: true });
        }
    }

    private initForm(data: AppWidgetState): void {
        this.saveWidgetForm = this.fb.group({
            id: [data.id],
            oldName: [data.name],
            name: [data.name, Validators.compose([
                Validators.required,
                CustomFormValidators.isInputSpaces,
                Validators.pattern(/^[\w\s/\-%&^#@()<>]+$/),
                Validators.maxLength(40)],
            )],
            displayNameType: [data.displayNameType],
            customDisplayName: [data.customDisplayName, Validators.compose([
                Validators.pattern(/^[\w\s/\-%&^#@()<>]+$/),
                Validators.maxLength(40)],
            )],
            enableWidgetHeader: [data.enableWidgetHeader],
            isSelectable: [data.isSelectable],
            description: [data.description],
            isSubscribedToDashboardFilters: [data.isSubscribedToDashboardFilters],
            enableAutoRefresh: [data.enableAutoRefresh],
            enableCompareMode: [data.enableCompareMode],
            isLinkedToMaster: [data.isLinkedToMaster],
            isShared: [data.isShared],
            version: [data.version],
        }, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validator: (group: any) => {
                if (group.controls.displayNameType.value === 'CUSTOM') {
                    return Validators.required(group.controls.customDisplayName);
                }
                group.controls.customDisplayName.reset(null, { onlySelf: true, emitEvent: false });
                return null;
            },
        });
    }

    /**
     * Marks all controls in a form group as touched
     */
    private markFormGroupTouched(formGroup = this.saveWidgetForm): void {
        if (!formGroup) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.keys(formGroup.controls).map((key) => formGroup.controls[key]).forEach((control: any) => {
            control.markAsTouched();
            if (control.controls) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                control.controls.forEach((c: any) => this.markFormGroupTouched(c));
            }
        });
    }

    private determineWidgetQueryNameAndQueryTypeName(
        datasetDefinition: DatasetDefinition | undefined,
    ): { queryName: string, queryTypeName: string } {
        let queryName = '';
        let queryTypeName = '';

        if (datasetDefinition && datasetDefinition.id !== -1) {
            queryName = datasetDefinition.name;
            queryTypeName = datasetDefinition.queryType?.name ?? '';
        } else {
            queryName = this.namedQuery?.name ?? '';
            queryTypeName = this.namedQuery?.type?.name ?? '';
        }
        return { queryName, queryTypeName };
    }
}
