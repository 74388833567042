<div class="summary-wrap">
    <app-widget-header
        [headers]="headers"
        [dashboardId]="dashboardId"
        [widgetId]="id"
        [widgetOnBoard]="widgetOnBoard"
        [userPreferences]="userPreferences"
        [dataLoadTime]="dataLoadTime"
        (widgetSaved)="onWidgetSaved($event)"
        (settingsChanged)="onWidgetSettingOverridesChanged($event.updatedWidget, $event.changes)"
        (headerMenuSelect)="onHeaderMenuSelect($event)"
    >
    </app-widget-header>

    <div *ngIf="summaryConfiguration && shouldDisplayVisualization()"
         class="summary-content"
         [ngClass]="{
                'small-padding': summaryConfiguration.textSize! < 20,
                'medium-padding': summaryConfiguration.textSize! >= 20 && summaryConfiguration.textSize! < 30,
                'large-padding': summaryConfiguration.textSize! >= 30
        }">

        <div class="content-spacer"></div>

        <div *ngIf="summaryConfiguration" class="content-list" [ngClass]="{'vertical-display': summaryConfiguration.display === 'vertical'}">
            <ul>
                <li
                        [ngClass]="{
                            'border': summaryConfiguration.isLineSeparatorEnabled && i !== summaries.length-1,
                            'redcolor': summary.isRed,
                            'right-alignment': summaryConfiguration.textAlignment === 'right',
                            'center-alignment': summaryConfiguration.textAlignment === 'center'
                        }"
                        *ngFor="let summary of summaries;let i = index">
                    <h5
                            class="summary-text"
                            [style.font-size.px]="summaryConfiguration.textSize">
                        {{summary.columnValue}}
                    </h5>

                    <label class="summary-label">{{summary.columnName}}</label>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="shouldDisplayMessage()" class="no-data">
        <span>{{getErrorMessage()}}</span>
    </div>

    <div
            class="no-data hi-error"
            *ngIf="isErrorOccurred && displayHIErrorMessage">
        <span>{{getHIErrorMessage()}}</span>
        <span>{{getHIAdditionalMessage()}}</span>
    </div>

    <div
            class="no-data"
            [class.error]="isErrorOccurred"
            *ngIf="isErrorOccurred && !displayHIErrorMessage">

        <span
            class="icon-notification-warning"
            (click)="showErrorMessage()">
        </span>
    </div>
</div>

<ng-template #fastnessCheckTemplate>
    <div class="fastness-title">Date Not Cached.</div>
    <div class="fastness-message">Data for this date is not cached. Do you wish to continue?</div>
</ng-template>
