import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiExecutorService, ApiServices, ExecutorService } from '@ddv/http';
import { NO_ENTITLEMENTS, Query } from '@ddv/models';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { catchError, map, Observable, of, switchMap, throwError, timer } from 'rxjs';

import {
    PublicAPIQueryResponse,
    TrebekQueryResponse,
    PublicApiResponseRow,
    TrebekQueryResponseRow, TrebekError, NotEntitledToHedgeInvestorDataError, GenericTrebekError,
} from '../models/trebek';
import { splitEndpointIntoClientAndRoute } from './metadata.service';

@Injectable()
export class DatasetTrebekQueryRunnerService {
    constructor(
        private readonly usageTracking: UsageTrackingService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
        private readonly executor: ExecutorService,
    ) {}

    runQuery(
        dashboardId: number | string | undefined, // only used for usage tracking
        widgetId: number,  // only used for usage tracking
        namedQueryId: number | string,
        dataQuery: Query,
        queryEndpoint: string,
        multiClientCodes: string[] | undefined,
    ): Observable<PublicAPIQueryResponse> {
        const usage: UsageTracker = this.usageTracking.startTracking(
            'DDV.Dataset.Data',
            {
                dashboardId,
                widgetId,
                definitionId: namedQueryId,
            });

        const isUsingNamedQuery = typeof namedQueryId === 'string';  // this should come from the query object
        const { clientCode, route } = finalizeRouteAndClientCode(isUsingNamedQuery, queryEndpoint, multiClientCodes);

        return this.callTrebek(
            isUsingNamedQuery,
            clientCode,
            route,
            dataQuery,
            usage,
        ).pipe(
            map((response: HttpResponse<TrebekQueryResponse>) => this.processResponse(response, usage)),
            catchError((error) => this.processError(error, usage)),
        );
    }

    private callTrebek(
        isUsingNamedQuery: boolean,
        clientCode: string,
        route: string,
        query: Query,
        usage: UsageTracker,
    ): Observable<HttpResponse<TrebekQueryResponse>> {
        let apiCall: Observable<HttpResponse<TrebekQueryResponse>>;
        if (isUsingNamedQuery) {
            apiCall = this.executor.invokeServiceWithBodyAndReturnFullResponse<TrebekQueryResponse>(
                route,
                'POST',
                query,
                { usageTracker: usage });
        } else {
            apiCall = this.trebekApiExecutor.invokeServiceWithBodyAndReturnFullResponse<TrebekQueryResponse>(
                clientCode,
                route,
                'POST',
                query,
                { usageTracker: usage });
        }

        return apiCall.pipe(
            switchMap((response) => {
                if (shouldRetryRequestLater(response)) {
                    return timer(retryDelay(response)).pipe(
                        switchMap(() => this.callTrebek(isUsingNamedQuery, clientCode, route, query, usage)),
                    );
                }

                return of(response);
            }),
        );
    }

    private processResponse(response: HttpResponse<TrebekQueryResponse>, usage: UsageTracker): PublicAPIQueryResponse {
        if (response?.body?.error) {
            throw createError(response.body.error);
        }

        usage.succeeded({ numRows: response.body?.data.length });
        return {
            data: translateTrebekResponseRowsToPublicApiResponseRows(response.body?.data ?? []),
            dataSourceName: response.body?.tracingInfo?.upstream,
        };
    }

    private processError(response: HttpErrorResponse | GenericTrebekError, usage: UsageTracker): Observable<never> {
        const error = response instanceof HttpErrorResponse ? createError(response.error || response.message) : response;

        usage.failed(error.rootCause);
        console.error(response);

        return throwError(() => error);
    }
}

function createError(error: { errorCode?: string, errorMessage?: string } | string | undefined): TrebekError {
    if (isNoInvestorDataEntitlementError(error)) {
        return new NotEntitledToHedgeInvestorDataError(error);
    }

    return new GenericTrebekError(error);
}

function isNoInvestorDataEntitlementError(
    error: { errorCode?: string, errorMessage?: string } | string | undefined,
): boolean {
    return typeof error !== 'string' &&
        error?.errorCode === NO_ENTITLEMENTS.CODE &&
        error.errorMessage === NO_ENTITLEMENTS.TEXT;
}

function finalizeRouteAndClientCode(
    isUsingNamedQuery: boolean,
    queryEndpoint: string,
    multiClientCodes: string[] | undefined,
): { clientCode: string, route: string } {
    let clientCode: string | undefined;
    let route: string;

    if (!isUsingNamedQuery) {
        clientCode = splitEndpointIntoClientAndRoute(queryEndpoint).clientCode;
        route = splitEndpointIntoClientAndRoute(queryEndpoint).route;
    } else {
        route = queryEndpoint;
        clientCode = '';
    }

    if (multiClientCodes) {
        route += `?clients=${multiClientCodes.join(',')}`;
    }

    return { clientCode, route };
}

function shouldRetryRequestLater(response: HttpResponse<TrebekQueryResponse>): boolean {
    return !!response?.headers?.get('x-hedgeserv-retry-after');
}

function retryDelay(response: HttpResponse<TrebekQueryResponse>): number {
    return Number(response.headers.get('x-hedgeserv-retry-after')) || 20000;
}

export function translateTrebekResponseRowsToPublicApiResponseRows(data: TrebekQueryResponseRow[]): PublicApiResponseRow[] {
    return data.map((row) => {
        const processed: PublicApiResponseRow = {
            ...row.selectedColumnData,
            links: row.links ?? {},
        } as PublicApiResponseRow;

        if (Object.prototype.hasOwnProperty.call(processed, 'date') && !Object.prototype.hasOwnProperty.call(processed, 'Date')) {
            processed.Date = processed.date;
        }

        return processed;
    });
}
