import { HI_DATA_USER_ERROR_MESSAGE, LinkConfiguration, TREBEK_QUERY_ERROR_MESSAGE, WidgetData } from '@ddv/models';

interface Links {
    links?: { [key: string]: LinkConfiguration };
}

export interface TrebekQueryResponseRow extends Links {
    selectedColumnData: WidgetData;
}

interface TrebekTracingInfo {
    upstream?: string;
    routedTo?: string;
    upstreamQuery?: {
        apiRouteUsed: string;
        method: string;
        body: Record<string, unknown> | null;
    }[];
}

export interface TrebekQueryResponse extends Links {
    data: TrebekQueryResponseRow[];
    tracingInfo?: TrebekTracingInfo;
    error?: string;
    queryId?: string;
}

export type PublicApiResponseRow = (WidgetData & Links);

export type DatedPublicApiResponseRow = (PublicApiResponseRow & { date?: string });

export abstract class TrebekError extends Error {
    public readonly timestamp = new Date().toISOString();

    protected constructor(
        public readonly rootCause: { errorCode?: string, errorMessage?: string } | string | undefined,
        message: string,
    ) {
        super(message);
    }
}

export class GenericTrebekError extends TrebekError {
    constructor(rootCause: { errorCode?: string, errorMessage?: string } | string | undefined) {
        super(rootCause, TREBEK_QUERY_ERROR_MESSAGE);
    }
}

export class NotEntitledToHedgeInvestorDataError extends TrebekError {
    constructor(rootCause: { errorCode?: string, errorMessage?: string } | string | undefined) {
        super(rootCause, HI_DATA_USER_ERROR_MESSAGE);
    }
}

export interface PublicAPIQueryResponse {
    data: PublicApiResponseRow[];
    dataSourceName: string | undefined;
}
