import { ApplicationRef, Injectable, ViewContainerRef } from '@angular/core';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import {
    MANAGE_WIDGET_WS_ID,
    WIDGET_LIFECYCLE_EVENT,
    LayoutHandler,
    LayoutResizeConfig,
    WorkspaceScrollBehaviour,
    WorkspaceSize,
    WorkspaceState,
    VisualizationMenuItem,
    AppWidgetState,
    InterWidgetCommunicationModel,
    MenuOptionConfig,
    Widget,
    WidgetAction,
    WidgetActionDetail,
    WidgetBehaviour,
    WidgetState,
    DashboardFilter,
    DashboardModel,
    WidgetLength,
    DatasetDefinitionDetails,
    NamedQuery,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { completeAssign } from '@ddv/utils';
import { BehaviorSubject, map, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import { AppLayoutState, WorkspaceStateChangeHandler } from '../models/app-layout-state';
import { ApplicationRegistrationModel } from '../models/app-registration-model';
import { Workspace } from '../models/workspace';

export interface LayoutHandlerDetail {
    layoutHandlers: { [layoutType: string]: LayoutHandler };
    workspaceSize: { width: number, height: number };
    layoutResizeConfig: LayoutResizeConfig;
}

export interface WorkspaceSelection {
    currentWorkspace?: Workspace;
    previousWorkspace?: Workspace;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class LayoutReducerUtil {
    static setWorkspaceSize(payload: Size, state: LayoutHandlerDetail): LayoutHandlerDetail {
        const resizeRatio = LayoutReducerUtil.getWorkspaceResizeRatio(payload, state.workspaceSize);
        const layoutResizeConfig: LayoutResizeConfig = {
            widthRatio: resizeRatio.width,
            heightRatio: resizeRatio.height,
            availableWidth: payload.width,
            availableHeight: payload.height,
        };
        return { ...state, workspaceSize: payload, layoutResizeConfig };
    }

    private static getWorkspaceResizeRatio(payload: Size, workspaceSize: WorkspaceSize): WorkspaceSize {
        const resizeRatio: WorkspaceSize = { width: 0, height: 0 };
        let widthR = 1;
        if (workspaceSize.width !== 0) {
            widthR = payload.width / workspaceSize.width;
        }
        widthR = Math.round(widthR * 100000) / 100000;
        let heightR = 1;
        if (workspaceSize.height !== 0) {
            heightR = payload.height / workspaceSize.height;
        }
        heightR = Math.round(heightR * 100000) / 100000;

        resizeRatio.width = widthR;
        resizeRatio.height = heightR;
        return resizeRatio;
    }
}

@Injectable()
export class ManagerService {
    public readonly currentDashboardId: Observable<string | number>;
    public readonly currentWorkspaceWidgets: Observable<Widget[]>;
    public readonly layoutHandlers: Observable<LayoutHandlerDetail>;
    public readonly workspaceSelection: Observable<WorkspaceSelection>;
    public readonly activeWidgets: Observable<Widget[]>;
    public readonly activeWorkspace: Observable<Workspace | undefined>;
    public readonly maximizeWidgetAction$: Observable<MaximizeWidgetAction>;
    public readonly isWidgetMaximized$: Observable<boolean>;
    public readonly selectedWidget$: Observable<SelectedWidgetAction>;
    public readonly isDateCompareActive$: Observable<boolean>;
    public readonly isDetailWidgetOpened: Observable<boolean>;

    private readonly isDateCompareActiveSubject: Subject<boolean> = new Subject();
    private readonly selectedWidgetSubject: BehaviorSubject<SelectedWidgetAction> = new BehaviorSubject<SelectedWidgetAction>({});
    private readonly isWidgetMaximizedSubject: Subject<boolean> = new Subject();
    private readonly maximizeWidgetActionSubject: BehaviorSubject<MaximizeWidgetAction> = new BehaviorSubject<MaximizeWidgetAction>({});
    private workspace: Workspace | undefined;
    private readonly currentDashboardIdSubject: Subject<string | number> = new ReplaySubject(1);
    private currentWorkspaceSelection: WorkspaceSelection | undefined;
    private widgets: Widget[] | undefined;
    private layoutHandlerDetail: LayoutHandlerDetail | undefined;
    private workspaceStateChangeHandler: WorkspaceStateChangeHandler | undefined;
    private readonly layoutHandlersObserver: Subscription;
    private readonly widgetActionSubject = new Subject<WidgetActionDetail>();
    private readonly isDetailWidgetOpenedSubject = new Subject<boolean>();
    private readonly removeWorkspaceActionSubject = new Subject<string>();
    private readonly currentWorkspaceWidgetsSubject: BehaviorSubject<Widget[]> = new BehaviorSubject([] as Widget[]);
    private readonly layoutHandlersSubject: BehaviorSubject<LayoutHandlerDetail> = new BehaviorSubject({
        layoutHandlers: {},
        workspaceSize: { width: 0, height: 0 },
        layoutResizeConfig: { widthRatio: 1, heightRatio: 1, availableWidth: 0, availableHeight: 0 },
    });
    private readonly workspaceSelectionSubject: BehaviorSubject<WorkspaceSelection> = new BehaviorSubject({});
    private readonly activeWidgetsSubject: BehaviorSubject<Widget[]> = new BehaviorSubject([] as Widget[]);
    private readonly activeWorkspaceSubject: BehaviorSubject<Workspace | undefined> = new BehaviorSubject<Workspace | undefined>(undefined);

    constructor(
        private readonly applicationRef: ApplicationRef,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly namedQueriesService: NamedQueriesService,
    ) {
        this.maximizeWidgetAction$ = this.maximizeWidgetActionSubject.asObservable();
        this.isWidgetMaximized$ = this.isWidgetMaximizedSubject.asObservable();
        this.selectedWidget$ = this.selectedWidgetSubject.asObservable();
        this.isDateCompareActive$ = this.isDateCompareActiveSubject.asObservable();

        this.activeWorkspace = this.activeWorkspaceSubject.asObservable();
        this.activeWorkspace.subscribe({
            next: (workspace) => this.workspace = workspace,
        });
        this.currentDashboardId = this.currentDashboardIdSubject.asObservable();

        this.activeWidgets = this.activeWidgetsSubject.asObservable();
        this.activeWidgets.subscribe((widgets: Widget[]) => {
            this.widgets = widgets;
        });

        this.workspaceSelection = this.workspaceSelectionSubject.asObservable();
        this.workspaceSelection.subscribe((currentWorkspaceSelection: WorkspaceSelection) => {
            this.currentWorkspaceSelection = currentWorkspaceSelection;
        });

        this.layoutHandlers = this.layoutHandlersSubject.asObservable();

        // yes, its subscribing to itself
        // no, i dont know why
        // no, im not going to touch it
        // yes, if you feel up for it you can
        this.layoutHandlersObserver = this.layoutHandlers
            .subscribe((layoutHandlerDetail: LayoutHandlerDetail) => {
                this.layoutHandlerDetail = layoutHandlerDetail;
            });

        this.currentWorkspaceWidgets = this.currentWorkspaceWidgetsSubject.asObservable();

        this.isDetailWidgetOpened = this.isDetailWidgetOpenedSubject.asObservable();
    }

    register(registrationModel: ApplicationRegistrationModel): void {
        this.registerLayoutHandlers(registrationModel.layoutHandlers);
        if (registrationModel.widgets) {
            this.registerWidgets(registrationModel.widgets, registrationModel.hideWidgetContentWhileDrag);
        }

        this.activeWorkspaceSubject.next(registrationModel.workspace);
        if (registrationModel.wsStateChangeHandler) {
            this.registerWorkspaceStateChangeHandler(registrationModel.wsStateChangeHandler);
        }
    }

    registerWorkspaceStateChangeHandler(wsStateChangeHandler: WorkspaceStateChangeHandler): void {
        this.workspaceStateChangeHandler = wsStateChangeHandler;
    }

    switchToWorkspace(): void {
        const currentWorkspaceSelection = this.workspaceSelectionSubject.getValue();
        if (currentWorkspaceSelection.currentWorkspace) {
            this.workspaceSelectionSubject.next({
                previousWorkspace: currentWorkspaceSelection.currentWorkspace,
                currentWorkspace: this.workspace,
            });
        } else {
            this.workspaceSelectionSubject.next({
                currentWorkspace: this.workspace,
            });
        }

        const currentWS = this.getWorkspace();
        this.updateCurrentWorkspaceWidgets(currentWS);
    }

    addWorkspace(workspace: Workspace, widgets?: Widget[]): void {
        this.activeWorkspaceSubject.next(workspace);
        this.currentDashboardIdSubject.next(workspace.id);
        this.workspace = workspace;

        widgets?.forEach((w) => {
            this.createWidget(w);
        });

        this.switchToWorkspace();
    }

    removeWorkspace(): void {
        if (!this.workspace) {
            return;
        }
        const workspaceId = this.workspace.id;

        const widgets = this.getWidgets();
        for (const widget of widgets) {
            this.removeWidgetById(widget.id);
        }

        const currentWorkspaceSelection = this.currentWorkspaceSelection;
        if (!currentWorkspaceSelection) {
            return;
        }

        currentWorkspaceSelection.previousWorkspace = this.workspace;
        currentWorkspaceSelection.currentWorkspace = undefined;
        this.notifyWorkspaceSelectionUpdate(currentWorkspaceSelection);

        this.activeWorkspaceSubject.next(undefined);
        this.removeWorkspaceActionSubject.next(workspaceId);
    }

    private notifyWorkspaceSelectionUpdate(workspaceSelection: WorkspaceSelection): void {
        const currentWorkspaceSelection: WorkspaceSelection = this.workspaceSelectionSubject.getValue();
        const newWorkspaceSelection: WorkspaceSelection = completeAssign([], [currentWorkspaceSelection, workspaceSelection]);
        this.workspaceSelectionSubject.next(newWorkspaceSelection);
    }

    createWidgets(init: { widget: Widget, state: AppWidgetState }[] | undefined): void {
        init?.forEach(({ widget, state }) => {
            this.createWidget(widget);
            this.setWidgetExtraPreferences(widget.id, state);
        });
    }

    createWidget(widget: Widget): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue() ?? [];
        const matchingWidgets = currentActiveWidgets.some((currentWidget) => currentWidget === widget);

        currentActiveWidgets.forEach((currentWidget) => currentWidget.bringInBackground());

        if (widget.selectOnLoad) {
            widget.bringInForeground();
        }

        if (!matchingWidgets) {
            const newActiveWidgets = Object.assign([], currentActiveWidgets);
            newActiveWidgets.push(widget);

            this.activeWidgetsSubject.next(newActiveWidgets);
        }

        const currentWS = this.getWorkspace();

        this.updateCurrentWorkspaceWidgets(currentWS);
    }

    private updateCurrentWorkspaceWidgets(workspace: Workspace | undefined): void {
        if (!workspace || !this.layoutHandlerDetail) {
            return;
        }

        const updatedWidgets = this.widgets ? [...this.widgets] : [];
        if (updatedWidgets.length) {
            const currentWSLayoutType = workspace.layoutType;
            const layoutHandler: LayoutHandler = this.layoutHandlerDetail.layoutHandlers[currentWSLayoutType];
            const workspaceSize: WorkspaceSize = this.layoutHandlerDetail.workspaceSize;
            const currentWidgets = this.currentWorkspaceWidgetsSubject.getValue();
            updatedWidgets.forEach((currentWSWidget) => {
                if (currentWidgets.indexOf(currentWSWidget) === -1) {
                    layoutHandler.configureWidget(workspace.id === MANAGE_WIDGET_WS_ID, currentWSWidget, workspaceSize);
                }
            });
        }

        this.currentWorkspaceWidgetsSubject.next(updatedWidgets);
    }

    private resizeCurrentWorkspaceWidgets(layoutType: string): void {
        const layoutHandler: LayoutHandler | undefined = this.layoutHandlerDetail?.layoutHandlers?.[layoutType];
        const updatedWidgets = [];
        for (const widget of this.currentWorkspaceWidgetsSubject.getValue()) {
            layoutHandler?.resizeWidget(widget, this.layoutHandlerDetail?.layoutResizeConfig);

            if (widget.lifeCycleCallBack) {
                widget.lifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE);
            }

            updatedWidgets.push(widget);
        }

        this.currentWorkspaceWidgetsSubject.next(updatedWidgets);
    }

    updateWidgetsVersion(widgets: { id: number, version: number}[]): void {
        widgets.forEach((widget) => {
            const widgetPref = this.getWidgetPreferences(widget.id);
            if (widgetPref) {
                widgetPref.version = widget.version;
            }
        });
    }

    removeAllWidgets(): void {
        const widgetStates: WidgetState[] = this.getWorkspaceWidgetsState() || [];
        widgetStates.forEach((widgetState) => {
            this.removeWidgetById(widgetState.id);
        });
    }

    removeWidgetById(id?: number): void {
        if (!id) {
            return;
        }

        this.notifyWidgetRemoved(id);

        const currentWS = this.getWorkspace();
        this.updateCurrentWorkspaceWidgets(currentWS);
    }

    private notifyWidgetRemoved(id: number): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.filter((widget) => {
            if (widget.id === id && widget.lifeCycleCallBack) {
                widget.lifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.ON_CLOSE);
            }
            return widget.id !== id;
        });

        this.activeWidgetsSubject.next(newActiveWidgets);
    }

    resizeWidget(widgetId: number, width: number, height: number): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.resize(width, height);
        }
    }

    moveWidget(widgetId: number, left: number, top: number): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.move(left, top);
        }
    }

    enableWidgetBehaviours(widgetId: number, widgetBehaviours: WidgetBehaviour[]): void {
        const widget = this.getWidgetById(widgetId);
        if (!widget) {
            return;
        }
        for (const widgetBehaviour of widgetBehaviours) {
            if (widgetBehaviour === WidgetBehaviour.DRAG) {
                widget.isDraggable = true;
            } else if (widgetBehaviour === WidgetBehaviour.RESIZE) {
                widget.isResizable = true;
            } else if (widgetBehaviour === WidgetBehaviour.CASCADE) {
                widget.allowCascade = true;
            } else if (widgetBehaviour === WidgetBehaviour.HEADER_DOUBLE_CLICK) {
                widget.allowHeaderDoubleClick = true;
            }
        }
    }

    disableWidgetBehaviours(widgetId: number, widgetBehaviours: WidgetBehaviour[]): void {
        const widget = this.getWidgetById(widgetId);
        if (!widget) {
            return;
        }
        for (const widgetBehaviour of widgetBehaviours) {
            if (widgetBehaviour === WidgetBehaviour.DRAG) {
                widget.isDraggable = false;
            } else if (widgetBehaviour === WidgetBehaviour.RESIZE) {
                widget.isResizable = false;
            } else if (widgetBehaviour === WidgetBehaviour.CASCADE) {
                widget.allowCascade = false;
            } else if (widgetBehaviour === WidgetBehaviour.HEADER_DOUBLE_CLICK) {
                widget.allowHeaderDoubleClick = false;
            }
        }
    }

    selectWidget(isWidgetSelected: boolean, widgetConfig?: Widget): void {
        this.selectedWidgetSubject.next({ isWidgetSelected, config: widgetConfig });
    }

    unselectWidget(widgetId: number): void {
        const widget = this.getWidgetById(widgetId);
        if (widget && !widget.maximized) {
            widget.bringInBackground();
        }
    }

    unselectAllWidgetsOfWorkspace(): void {
        const widgets = this.getWidgets();
        widgets.forEach((widget) => {
            widget.bringInBackground();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    triggerWidgetAction(widgetId: number, action: WidgetAction, extraParams?: any): void {
        this.widgetActionSubject.next({ widgetId, action, extraParams });
    }

    configureWidgetContentVisibilityWhileDrag(visibility: boolean): void {
        Widget.setContentVisibilityWhileDrag(visibility);
    }

    getWidgetContainerRef(widgetId: number): ViewContainerRef | undefined {
        const widget = this.getWidgetById(widgetId);
        return widget?.containerRef;
    }

    setInitialWorkspaceSize(width: number, height: number): void {
        this.resizeWorkspace(width, height);
    }

    resizeWorkspace(width: number, height: number): void {
        if (!width && !height) {
            return;
        }

        this.notifyWorkspaceSizeChange(width, height);
        const currentWSLayoutType = this.getWorkspace()?.layoutType;
        if (currentWSLayoutType) {
            this.resizeCurrentWorkspaceWidgets(currentWSLayoutType);
        }

        this.triggerWorkspaceStateChange(WIDGET_LIFECYCLE_EVENT.AFTER_WORKSPACE_RESIZE);
    }

    private notifyWorkspaceSizeChange(width: number, height: number): void {
        const currentLayoutDetails: LayoutHandlerDetail = this.layoutHandlersSubject.getValue();
        const newLayoutDetails = LayoutReducerUtil.setWorkspaceSize({ width, height }, currentLayoutDetails);
        this.layoutHandlersSubject.next(newLayoutDetails);
    }

    setCurrentWorkspaceSize(width: number, height: number): void {
        const workspaceSizeBeforeResize = this.layoutHandlerDetail?.workspaceSize;
        if (workspaceSizeBeforeResize && (workspaceSizeBeforeResize.width !== width || workspaceSizeBeforeResize.height !== height)) {
            this.notifyWorkspaceSizeChange(width, height);
            this.resizeWidgetBasedOnWorkspaceScrollBehaviour(workspaceSizeBeforeResize, width, height);
        }
    }

    isManagingWidget(): boolean {
        return this.getCurrentDashboardId() === MANAGE_WIDGET_WS_ID;
    }

    getCurrentDashboardId(): string | undefined {
        return this.currentWorkspaceSelection?.currentWorkspace?.id;
    }

    getCurrentDashboardFilters(): DashboardFilter[] {
        return this.currentWorkspaceSelection?.currentWorkspace?.extraParameters?.dashboardPreferences[0]?.filters ?? [];
    }

    getWorkspace(): Workspace | undefined {
        return this.workspace;
    }

    getWorkspaceScrollBehaviour(): WorkspaceScrollBehaviour | undefined {
        const currentWorkspace = this.getWorkspace();
        return currentWorkspace?.workspaceScrollBehaviour;
    }

    getWorkspaceState(): WorkspaceState | undefined {
        const workspace = this.getWorkspace();
        return workspace?.getWorkspaceState();
    }

    getWorkspaceLayoutHandler(): LayoutHandler | undefined {
        const currentWS = this.getWorkspace();
        const layoutType = currentWS?.layoutType;
        return layoutType ? this.layoutHandlerDetail?.layoutHandlers?.[layoutType] : undefined;
    }

    getCurrentWorkspaceSize(): WorkspaceSize | undefined {
        return this.layoutHandlerDetail?.workspaceSize;
    }

    getWorkspaceStateChangeHandler(): WorkspaceStateChangeHandler | undefined {
        return this.workspaceStateChangeHandler;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getExtraParametersForWorkspace(): any {
        const workspace = this.getWorkspace();
        // this is largely to work around the mess that is our unit tests
        // many use a fake workspace object that doesn't have the function but does have the property
        return workspace ? (workspace.getExtraParameters ? workspace.getExtraParameters() : workspace.extraParameters) : null;
    }

    setExtraParametersForWorkspace(extraParameters: unknown): void {
        const workspace = this.getWorkspace();
        if (workspace) {
            // this cast is probably a lie sense the type for the extraParametersForWorkspace are `unknown` everywhere
            workspace.setExtraParameters(extraParameters as DashboardModel);
        }
    }

    sendMessageToWidgetOnWorkspace(communicationModel: InterWidgetCommunicationModel): void {
        const widget = communicationModel.widget;
        const widgetInsts = this.getWidgetBySelector(widget.selector);
        if (widgetInsts.length === 0) {
            widget.lifeCycleCallBack = undefined;
            this.createWidget(widget);
            widgetInsts.push(widget);
            // Manually triggering change detection to happen
            // When widget is not already opened and we send message to widget then inter-widget communication event was triggered first
            // then component constructor was getting called.
            // But we wanted inter-widget communication event to be triggered only after widget is rendered in dom.
            this.applicationRef.tick();
        }

        widgetInsts.forEach((w) => {
            if (w.id !== communicationModel.initiatorWidgetId) {
                /**
                 * initiatorWidgetId is added to fix the issue where if call initiator widget has the same selector as target widget
                 * then  call initiator widget was also getting called apart from target widget.
                 */
                w.lifeCycleCallBack?.(WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, communicationModel.data);
            }
        });
    }

    sendMessageToAllWidgetsOnWorkspace(initiatorWidgetId: number, data: unknown): void {
        const widgets = this.getWidgets();
        for (const widget of widgets) {
            if (initiatorWidgetId !== widget.id) {
                widget.lifeCycleCallBack?.(WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data);
            }
        }
    }

    showMenuOptionForWidget(widgetId: number, menuOptionSelectors: string[]): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.map((widget) => {
            if (widget.id === widgetId) {
                widget.showMenuIcons(menuOptionSelectors);
            }
            return widget;
        });

        this.activeWidgetsSubject.next(newActiveWidgets);
    }

    hideMenuOptionForWidget(widgetId: number, menuOptionSelectors: string[]): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.map((widget) => {
            if (widget.id === widgetId) {
                widget.hideMenuIcons(menuOptionSelectors);
            }
            return widget;
        });

        this.activeWidgetsSubject.next(newActiveWidgets);
    }

    enableAllMenuIconsForWidget(widgetId: number): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.enableAllMenuIcons();
        }
    }

    enableMenuIconsForWidget(widgetId: number, menuIconsSelectors: string[]): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.enableMenuIcons(menuIconsSelectors);
        }
    }

    disableAllMenuIconsForWidget(widgetId: number): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.disableAllMenuIcons();
        }
    }

    disableMenuIconsForWidget(widgetId: number, menuIconsSelectors: string[]): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.disableMenuIcons(menuIconsSelectors);
        }
    }

    updateMenuOptionStyleForWidget(widgetId: number, menuOptionSelector: string, styleClass: string): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.map((widget) => {
            if (widget.id === widgetId) {
                widget.updateMenuOptionStyleClass(menuOptionSelector, styleClass);
            }
            return widget;
        });

        this.activeWidgetsSubject.next(newActiveWidgets);
    }

    addMenuOptionsForWidget(widgetId: number, menuOptionConfigs: MenuOptionConfig[]): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.map((widget) => {
            if (widget.id === widgetId) {
                widget.addMenuOptions(menuOptionConfigs);
            }
            return widget;
        });

        this.activeWidgetsSubject.next(newActiveWidgets);
    }

    getApplicationLayoutState(): AppLayoutState {
        const workspaceState = this.getApplicationWorkspaceState();
        const widgetsState = this.getApplicationWidgetsState();
        return new AppLayoutState(workspaceState, widgetsState);
    }

    getWidgetState(widgetId: number): WidgetState | undefined {
        const widget = this.getWidgetById(widgetId);
        return widget?.getState();
    }

    getWorkspaceWidgetsState(): WidgetState[] {
        return this.widgets?.map((w) => w.getState()) ?? [];
    }

    getAppWidgetsState(): AppWidgetState[] {
        return (this.widgets?.map((w) => w.getState()) ?? [])
            .map((ws) => {
                if (ws?.id) {
                    return Object.assign(this.getWidgetPreferences(ws.id) ?? {}, ws) as AppWidgetState;
                } else {
                    return ws as AppWidgetState;
                }
            });
    }

    getWidgetContentSize(widgetId: number): WidgetLength | undefined {
        const widget = this.getWidgetById(widgetId);
        return widget?.getContentSize();
    }

    updateWidgetState(widgetId: number, widgetState: WidgetState): void {
        const currentState = this.getWidgetState(widgetId);
        const widget = this.getWidgetById(widgetId);

        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.map((currentWidget) => {
            if (currentWidget.id === widgetId) {
                currentWidget.updateState(widgetState);
            }
            return currentWidget;
        });
        this.activeWidgetsSubject.next(newActiveWidgets);

        if (currentState) {
            if (currentState.height !== widgetState.height || currentState.width !== widgetState.width) {
                widget?.lifeCycleCallBack?.(WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE);
            }

            if (currentState.left !== widgetState.left || currentState.top !== widgetState.top) {
                widget?.lifeCycleCallBack?.(WIDGET_LIFECYCLE_EVENT.AFTER_DRAG);
            }
        }
    }

    updateWidgetTitle(widgetId: number, title: string): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const newActiveWidgets = currentActiveWidgets.map((widget) => {
            if (widget.id === widgetId) {
                widget.updateTitle(title);
            }
            return widget;
        });

        this.activeWidgetsSubject.next(newActiveWidgets);
    }

    getWidgetActionObservable(): Observable<WidgetActionDetail> {
        return this.widgetActionSubject.asObservable();
    }

    getWorkSpaceActionObservable(): Observable<string> {
        return this.removeWorkspaceActionSubject.asObservable();
    }

    getWidgetPreferences(widgetId: number): AppWidgetState | undefined | null {
        const widget = this.getWidgetById(widgetId);
        return widget?.getExtraPreferences();
    }

    getWidgetReload(widgetId: number): boolean | undefined | null {
        return this.getWidgetById(widgetId)?.getExtraReload();
    }

    // the union with undefined is because the type for many things that have the widgetId
    // on them needed to be set to number | undefined because of the stupid Object.assign in all constructors
    setWidgetExtraPreferences(widgetId: number | undefined, preferences: AppWidgetState): void {
        const widget = widgetId && this.getWidgetById(widgetId);
        if (widget) {
            widget.setExtraPreferences(preferences);
        }
    }

    setWidgetExtraReload(widgetId: number, reload: boolean): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.setExtraReload(reload);
        }
    }

    updateIsDetailWidgetOpened(isOpened: boolean): void {
        this.isDetailWidgetOpenedSubject.next(isOpened);
    }

    private registerWidgets(widgets: Widget[], hideWidgetContentWhileDrag?: boolean): void {
        const currentActiveWidgets = this.activeWidgetsSubject.getValue();
        const nextActiveWidgets = Object.assign([], currentActiveWidgets, widgets);

        this.activeWidgetsSubject.next(nextActiveWidgets);

        if (hideWidgetContentWhileDrag !== undefined) {
            this.configureWidgetContentVisibilityWhileDrag(hideWidgetContentWhileDrag);
        }
    }

    private registerLayoutHandlers(layoutHandlers: { [layoutType: string]: LayoutHandler }): void {
        const currentLayoutDetail: LayoutHandlerDetail = this.layoutHandlersSubject.getValue();
        const newLayoutDetail = { ...currentLayoutDetail, layoutHandlers };

        this.layoutHandlersSubject.next(newLayoutDetail);
    }

    getWidgetById(widgetId: number): Widget | undefined {
        return this.widgets?.find((w) => w.id === widgetId);
    }

    private getWidgetBySelector(selector: string): Widget[] {
        const widgets = this.getWidgets();
        return widgets.filter((w) => w.selector === selector);
    }

    private getWidgets(): Widget[] {
        return this.widgets ? [...this.widgets] : [];
    }

    private getApplicationWorkspaceState(): WorkspaceState | undefined {
        return this.workspace?.getWorkspaceState();
    }

    private getApplicationWidgetsState(): WidgetState[] {
        return this.widgets?.map((w) => w.getState()) ?? [];
    }

    updateWidgetTempState(widgetId: number, widgetState: WidgetState): void {
        const widget = this.getWidgetById(widgetId);
        if (widget) {
            widget.copyWidgetStateToTemp(widgetState);
        }
    }

    private resizeWidgetBasedOnWorkspaceScrollBehaviour(workspaceSizeBeforeResize: WorkspaceSize, width: number, height: number): void {
        const currentWorkspace: Workspace | undefined = this.getWorkspace();
        const workspaceScrollBehaviour: WorkspaceScrollBehaviour | undefined = this.getWorkspaceScrollBehaviour();

        let allowWidgetResize = false;
        if (!workspaceScrollBehaviour) {
            allowWidgetResize = true;
        } else {
            if (!workspaceScrollBehaviour.allowInfiniteHScroll && !workspaceScrollBehaviour.allowInfiniteVScroll) {
                allowWidgetResize = true;
            } else if (!workspaceScrollBehaviour.allowInfiniteHScroll && workspaceSizeBeforeResize.width !== width) {
                allowWidgetResize = true;
            } else if (!workspaceScrollBehaviour.allowInfiniteVScroll && workspaceSizeBeforeResize.height !== height) {
                allowWidgetResize = true;
            }
        }
        if (allowWidgetResize && currentWorkspace?.layoutType) {
            this.resizeCurrentWorkspaceWidgets(currentWorkspace?.layoutType);
        }
    }

    private triggerWorkspaceStateChange(eventName: WIDGET_LIFECYCLE_EVENT.AFTER_WORKSPACE_RESIZE): void {
        if (typeof this.workspaceStateChangeHandler === 'function') {
            this.workspaceStateChangeHandler(
                this.getApplicationLayoutState(),
                this.getWorkspace()?.isRendered ?? false,
                eventName);
        }
    }

    getWidgetIdsForWorkspace(): number[] {
        return this.widgets?.map((widget) => widget.id) ?? [];
    }

    sendMessageToExistingWidget(widgetId: number, data: unknown): void {
        const widgetInst = this.getWidgetById(widgetId);
        if (widgetInst?.lifeCycleCallBack) {
            widgetInst.lifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data);
        }
    }

    setMaximizeWidgetActionSubjectValue(options: { toBeMaximized: boolean, widgetId: number, item?: VisualizationMenuItem }): void {
        this.maximizeWidgetActionSubject.next(options);
    }

    emitIsWidgetMaximizedSubjectValue(isMax: boolean): void {
        this.isWidgetMaximizedSubject.next(isMax);
    }

    emitWhenDateComparerIsToggled(isChecked: boolean): void {
        this.isDateCompareActiveSubject.next(isChecked);
    }

    updateWidgetsOnMasterFiltersRemoved(): void {
        const widgets = this.getWidgetIdsForWorkspace().map((id) => ({ ...this.getWidgetById(id) }));
        for (const widget of widgets) {
            if (widget.lifeCycleCallBack) {
                widget.lifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.MASTER_FILTERS_REMOVED);
            }
        }
    }

    isStackedQuery(datasetId: number | string): Observable<boolean> {
        const fetch$: Observable<DatasetDefinitionDetails | NamedQuery> = typeof datasetId === 'string' ?
            this.namedQueriesService.fetchNamedQuery(datasetId) :
            this.datasetDefinitionsService.fetchDatasetDefinitionDetails(datasetId);
        return fetch$.pipe(
            map((namedQuery) => namedQuery.normalizeQueryIsStacked()),
        );
    }
}

interface Size {
    width: number;
    height: number;
}

export interface MaximizeWidgetAction {
    toBeMaximized?: boolean;
    widgetId?: number;
    item?: VisualizationMenuItem;
}

export interface SelectedWidgetAction {
    isWidgetSelected?: boolean;
    config?: Widget;
}
