import { Injectable } from '@angular/core';
import { CompareMode, DatasetFetchRuntimeParams, DateRange, Fund, QueryParams, QueryTypeName } from '@ddv/models';
import { ClientsService, FundsService } from '@ddv/reference-data';

import { DefaultQueryParamsService } from './default-query-params.service';

@Injectable()
export class DatasetRuntimeParamsBuilderService {
    private defaultQueryParams: QueryParams = {};
    private allClientCodes: string[] = [];
    private allFunds: Fund[] = [];

    constructor(
        defaultQueryParamsService: DefaultQueryParamsService,
        clientsService: ClientsService,
        fundsService: FundsService,
    ) {
        defaultQueryParamsService.defaultQueryParams$
            .subscribe({
                next: (defaultQueryParams) => this.defaultQueryParams = defaultQueryParams,
            });

        clientsService.clients()
            .subscribe({
                next: (clientCodes) => this.allClientCodes = clientCodes,
            });

        fundsService.funds()
            .subscribe({
                next: (funds) => this.allFunds = funds,
            });
    }

    build(
        queryType: string,
        compareMode: CompareMode | undefined,
        queryParams: QueryParams | undefined,
        isMultiClient: boolean,
    ): DatasetFetchRuntimeParams {
        return {
            timeseriesGranularity: 'daily',
            dateRange: this.buildDateRange(compareMode, queryParams),
            fundCodes: this.buildFundCodes(queryParams, queryType),
            includeLinks: true,
            includeComments: true,
            clientCodeList: this.buildMultiClientList(isMultiClient, queryParams),
            specialCaseParams: {},
        };
    }

    private buildDateRange(compareMode: CompareMode | undefined, queryParams: QueryParams | undefined): DateRange {
        const dateFrom = compareMode === CompareMode.COMPARED && queryParams?.compareDates ?
            queryParams?.compareDates.dateFrom :
            queryParams?.startDate ?? this.defaultQueryParams.startDate;
        const dateTo = compareMode === CompareMode.COMPARED && queryParams?.compareDates ?
            queryParams.compareDates.dateTo :
            queryParams?.endDate ?? this.defaultQueryParams.endDate;

        return {
            from: dateFrom ?? '',
            to: dateTo ?? '',
        };
    }

    private buildFundCodes(queryParams: QueryParams | undefined, queryType: string): { code: string }[] {
        let fundCodes = (queryParams?.funds?.length ? queryParams : this.defaultQueryParams)?.funds?.map((fund) => fund.fundId) ?? [];
        if (fundCodes.length && this.allFunds.length) {
            fundCodes = fundCodes.filter((fundCode) => shouldFundBeIncluded(this.allFunds, fundCode, queryType));
        }

        return fundCodes.map((code) => ({ code }));
    }

    private buildMultiClientList(isMultiClient: boolean, queryParams: QueryParams | undefined): string[] | undefined {
        if (!isMultiClient) {
            return;
        }

        if (queryParams?.clients?.length) {
            return queryParams?.clients?.map((c) => c.clientId);
        }

        return this.allClientCodes;
    }
}

function shouldFundBeIncluded(funds: Fund[], fundCode: string, queryType: string): boolean {
    const fund = funds.find((f) => f.fundId === fundCode);
    const isInvestorQuery = queryType === QueryTypeName.ECDI ||
        queryType === QueryTypeName.INVESTOR ||
        queryType === QueryTypeName.INVESTORS_ACTIVITY ||
        queryType === QueryTypeName.INVESTORS_PERFORMANCE ||
        queryType === QueryTypeName.INVESTORS_ASSETS;
    return !!fund && (isInvestorQuery ? fund.isHiOrBoth : fund.isHsMainOrBoth);
}

