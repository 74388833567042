import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardService } from '@ddv/dashboards';
import { CustomColDef } from '@ddv/data-grid';
import { CrosstalkDataService, Datasource, MetadataService, WidgetDataSourceService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { Workspace, ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    WIDGET_LIFECYCLE_EVENT,
    crosstalkCheckboxFieldId,
    UserDefinedFieldType,
    CompareColumnID,
    WidgetData,
    DdvDate,
    DdvDateTime,
    UserPreferences,
    ExportDatasetInfo,
    ExportDatasetWidgetInfo,
    ExportInfo,
    ExportWidgetInfo,
    TExportFormat,
    ConfigItem,
    MetadataLookup,
    FieldMetadata,
} from '@ddv/models';
import { deepClone } from '@ddv/utils';
import { FilteredDataService, WidgetExportService, ExportableData } from '@ddv/widgets';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { combineLatest, switchMap, tap } from 'rxjs';

import { DashboardPDFExportService } from '../../services/dashboard-pdf-export';

interface ExportRequestParameter {
    dashboardName: string;
    dashboardId: string;
    dataSet: ExportDatasetInfo[];
}

enum ExportType {
    FULL = 'Full',
    FILTERED = 'Filtered'
}

const SPECIAL_CHARS_REGEX = /[\\/?:*"|<>#()]/g;

@Component({
    selector: 'app-dashboard-export',
    templateUrl: 'dashboard-export.component.html',
    styleUrls: ['dashboard-export.component.scss'],
})
export class DashboardExportComponent extends MultiSubscriptionComponent implements OnInit {
    @Input() isWidgetExport = false;
    @Input() widgetId: number | undefined;
    @Output() exportOptionsClose = new EventEmitter<ExportInfo>();

    isFullExport = false;
    selectAll = { isChecked: false, value: false };
    selectedWidgetCount = 0;
    includeCrosstalkKeys = false;
    showCrosstalkKeysExportToggle = false;

    // these are just a data-struct pulled from various places that give the inputs you need to collect all the data to export
    protected exportWidgetInfos: ExportWidgetInfo[] = [];

    private currentDashboard: Workspace | undefined;
    private exportFormat: TExportFormat | undefined;
    private exportInfo: ExportInfo | undefined;
    private metadata: MetadataLookup | { [widgetId: number | string]: MetadataLookup } | undefined;
    private requestParam: ExportRequestParameter | undefined;
    private selectedWidgetInfos: ExportWidgetInfo[] = [];
    private gridsSelectedWidgetsInfos: ExportWidgetInfo[] = [];
    private nonGridsSelectedWidgetsInfos: ExportWidgetInfo[] = [];
    private sequenceNo = 1;
    private gridsFullExportColumns: { widgetId: string, columns: string[] | undefined }[] | undefined;
    private gridsMetadata: MetadataLookup | { [widgetId: number | string]: MetadataLookup } | undefined;
    private nonGridsMetadata: MetadataLookup | { [widgetId: number | string]: MetadataLookup } | undefined;
    private userPreferences: UserPreferences = new UserPreferences();
    private theme: Theme = Theme.light;
    private widgetDatasource: Datasource | undefined;

    constructor(
        private readonly dashboardPdfExportService: DashboardPDFExportService,
        private readonly dashboardService: DashboardService,
        private readonly filteredDataService: FilteredDataService,
        private readonly managerService: ManagerService,
        private readonly metadataService: MetadataService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly userService: UserService,
        private readonly exportService: WidgetExportService,
        private readonly themeService: ThemeService,
        private readonly crosstalkDataService: CrosstalkDataService,
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([this.userService.userPreferences$, this.themeService.currentTheme$])
            .subscribe(([userPreferences, theme]) => {
                this.userPreferences = userPreferences;
                this.isFullExport = !!this.userPreferences.isFullExport;
                this.theme = theme;
            });

        this.subscribeTo(this.widgetDataSourceService.dataSource$, (dataSource) => this.widgetDatasource = dataSource);

        this.currentDashboard = this.managerService.getWorkspace();
        this.exportWidgetInfos = this.managerService.getAppWidgetsState()
            .map((widget) => {
                return {
                    id: widget.id ?? 0,
                    isSubscribedToDashboardFilters: !!widget.isSubscribedToDashboardFilters,
                    name: (widget.displayNameType === 'CUSTOM' ? widget.customDisplayName : widget.name) ?? '',
                    isChecked: false,
                    datasetDefinition: widget.datasetDefinition!,
                    namedQueryId: widget.namedQueryId,
                    currentVisualization: widget.currentVisualization,
                    currentVisualizationConfig: widget.visualizationConfigs.find((config) => {
                        return config.visualizationType === widget.currentVisualization;
                    }),
                };
            })
            .sort((a, b) => (a.name ?? '').toLowerCase().localeCompare((b.name ?? '').toLowerCase()));

        this.showCrosstalkKeysExportToggle = !!this.currentDashboard?.extraParameters?.widgets.some((widget) => {
            return widget.datasetDefinition?.conversableType && widget.currentVisualization === 'ADVANCED_GRID';
        });

        this.exportService.updateIncludeCrosstalkKeys(false);
    }

    get dataIsLoaded(): boolean {
        return !!(this.widgetDatasource && this.widgetDatasource.datasources.length > 0);
    }

    exportDashboard(exportFormat: TExportFormat): void {
        this.exportFormat = exportFormat;
        this.setExportInfo();
        this.sequenceNo = 1;

        if (this.isWidgetExport) {
            this.selectedWidgetInfos = this.exportWidgetInfos.filter((ew) => ew.id === this.widgetId);
            this.selectedWidgetCount = 1;
        } else {
            this.selectedWidgetInfos = this.exportWidgetInfos.filter((ew) => ew.isChecked);
        }

        if (!this.selectedWidgetInfos.length && this.exportFormat === 'PDF') {
            const widgets = this.exportWidgetInfos.map((widget) => ({
                widgetName: widget.name,
                widgetId: widget.id.toString(),
                columnNames: [],
            }));
            this.requestParam = this.getExportRequestParams(undefined);
            this.requestParam.dataSet.push({ metadata: undefined, widgets, data: [], summary: undefined });
            this.exportData();
        }

        if (!this.selectedWidgetInfos.length) {
            this.sendNoWidgetMessage();
            return;
        }

        this.gridsSelectedWidgetsInfos = this.selectedWidgetInfos.filter((widget) => {
            return widget.currentVisualization?.includes('GRID');
        });

        if (this.isFullExport) {
            this.nonGridsSelectedWidgetsInfos = this.selectedWidgetInfos.filter((widget) => {
                return !widget.currentVisualization?.includes('GRID');
            });

            let gridContexts: BoardWidgetDefinitionIds[] | undefined;
            let nonGridContexts: BoardWidgetDefinitionIds[] | undefined;

            if (this.gridsSelectedWidgetsInfos.length) {
                gridContexts = this.exportInfosToMetadataFetchContexts(this.gridsSelectedWidgetsInfos);
            }

            if (this.nonGridsSelectedWidgetsInfos.length) {
                nonGridContexts = this.exportInfosToMetadataFetchContexts(this.nonGridsSelectedWidgetsInfos);
            }

            this.selectedWidgetInfos.forEach((selectedWidget) => {
                this.managerService.sendMessageToExistingWidget(selectedWidget.id, WIDGET_LIFECYCLE_EVENT.EXPORT_FULL_DATA);
            });

            this.requestParam = {
                dashboardName: this.currentDashboard?.getExtraParameters().name,
                dashboardId: this.currentDashboard?.id.toString() ?? '',
                dataSet: [],
            };

            if (gridContexts && !nonGridContexts) {
                this.subscribeTo(this.metadataService.fetchMetadataAllMetadata(gridContexts)
                    .pipe(
                        tap((gridsMetadata) => {
                            this.gridsMetadata = gridsMetadata;
                        }),
                        switchMap(() => this.filteredDataService.fullData$)), (fullData) => this.exportFullDashboard(fullData));
            }

            if (!gridContexts && nonGridContexts) {
                this.subscribeTo(this.metadataService.fetchMetadataAllMetadata(nonGridContexts)
                    .pipe(
                        tap((nonGridsMetadata) => {
                            this.nonGridsMetadata = nonGridsMetadata;
                        }),
                        switchMap(() => this.filteredDataService.fullData$)), (fullData) => this.exportFullDashboard(fullData));
            }

            if (gridContexts && nonGridContexts) {
                this.subscribeTo(this.metadataService.fetchMetadataAllMetadata([...gridContexts, ...nonGridContexts])
                    .pipe(
                        tap((metadata) => {
                            this.gridsMetadata = {};
                            this.nonGridsMetadata = {};
                            for (const widgetId in metadata) {
                                if (gridContexts?.find((gc) => gc.widgetId?.toString() === widgetId)) {
                                    this.gridsMetadata = {
                                        ...this.gridsMetadata,
                                        [widgetId]: metadata[widgetId],
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    } as any;
                                } else if (nonGridContexts?.find((gc) => gc.widgetId?.toString() === widgetId)) {
                                    this.nonGridsMetadata = {
                                        ...this.nonGridsMetadata,
                                        [widgetId]: metadata[widgetId],
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    } as any;
                                }
                            }
                        }),
                        switchMap(() => this.filteredDataService.fullData$)), (fullData) => this.exportFullDashboard(fullData),
                );
            }
        } else {
            const contexts: BoardWidgetDefinitionIds[] = this.exportInfosToMetadataFetchContexts(this.selectedWidgetInfos);

            this.subscribeTo(this.metadataService.fetchMetadataAllMetadata(contexts)
                .pipe(
                    tap((metadata) => {
                        this.metadata = metadata;
                        this.selectedWidgetInfos.forEach((widget) => {
                            if (this.exportFormat === 'CSV' && widget.currentVisualization === 'ADVANCED_GRID') {
                                this.managerService.sendMessageToExistingWidget(
                                    widget.id, WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_ADVANCED_GRID_DATA_TO_CSV);
                            } else {
                                this.managerService.sendMessageToExistingWidget(
                                    widget.id, WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA);
                            }
                        });
                    }),
                    switchMap(() => this.filteredDataService.filteredData$)), (filteredData) => this.exportFilteredDashboard(filteredData));
        }
    }

    onExportTypeChange(isFilteredExport: boolean): void {
        this.isFullExport = !isFilteredExport;
        this.userPreferences.isFullExport = this.isFullExport;
        this.userService.updateUserPreferenceData(this.userPreferences).subscribe();
    }

    onCrosstalkKeyExportChange(excludeCrosstalkKeys: boolean): void {
        this.includeCrosstalkKeys = !excludeCrosstalkKeys;
        this.exportService.updateIncludeCrosstalkKeys(!excludeCrosstalkKeys);
    }

    private getSubscribedWidgetDatasets(
        metadata: { [widgetId: number | string]: MetadataLookup },
        isGrid: boolean,
        exportData?: Record<string, ExportableData>,
    ): ExportDatasetInfo[] {
        if (!this.dataIsLoaded) {
            throw new Error('Cannot export - data sources have not loaded');
        }

        const exportDatasetInfos: ExportDatasetInfo[] = [];
        const selectedWidgetInfos = isGrid ? this.gridsSelectedWidgetsInfos : this.nonGridsSelectedWidgetsInfos;
        selectedWidgetInfos.forEach((widgetInfo) => {
            const widgetColumns = this.getWidgetColumns(widgetInfo.currentVisualizationConfig?.configs?.values);
            const dateTypeOverrides = this.getDataTypeOverrides(widgetInfo, metadata);

            if (!widgetInfo.isSubscribedToDashboardFilters) {
                return;
            }

            let udfColumns: ConfigItem[] = [];
            if (widgetInfo.currentVisualization === 'ADVANCED_GRID') {
                udfColumns = widgetInfo.currentVisualizationConfig?.configs?.values
                    .filter((column) => this.exportService.isUserDefinedFieldColumn(column.name))
                    .map((column) => {
                        return { colId: column.name, userDefinedFieldType: column.datatype as UserDefinedFieldType } as ConfigItem;
                    }) ?? [];
            }
            const namedQueryId = widgetInfo.namedQueryId ?? widgetInfo.datasetDefinition.id!;
            let data = this.getWidgetData('dashboard', namedQueryId, udfColumns);
            const columns = this.getColumns(widgetInfo, isGrid, widgetColumns, metadata);
            data = this.addDataToCompareColumns(widgetInfo, columns, data, exportData);
            const widgets = this.getWidgets(widgetInfo, columns, exportData ?? {}, dateTypeOverrides);

            let crosstalkFields: { name: string, displayName: string }[] | undefined;
            if (this.exportService.shouldIncludeCrosstalkKeys(widgetInfo)) {
                data = this.addCrosstalkKeysData(widgetInfo, data);
                crosstalkFields = this.crosstalkDataService.getCrosstalkSchemaFields(namedQueryId);
            }
            exportDatasetInfos.push({ metadata: metadata[widgetInfo.id], widgets, data, summary: {}, crosstalkFields });

            this.sequenceNo += 1;
        });
        return exportDatasetInfos;
    }

    private getUnsubscribedWidgetDatasets(
        metadata: { [widgetId: number | string]: MetadataLookup },
        isGrid: boolean,
        exportData?: Record<string, ExportableData>,
    ): ExportDatasetInfo[] {
        if (!this.dataIsLoaded) {
            throw new Error('Cannot export - data sources have not loaded');
        }
        const exportDatasetInfos: ExportDatasetInfo[] = [];
        const selectedWidgetInfos: ExportWidgetInfo[] = isGrid ? this.gridsSelectedWidgetsInfos : this.nonGridsSelectedWidgetsInfos;
        selectedWidgetInfos.forEach((widgetInfo) => {
            const widgetColumns = this.getWidgetColumns(widgetInfo.currentVisualizationConfig?.configs?.values);
            const dateTypeOverrides = this.getDataTypeOverrides(widgetInfo, metadata);
            if (!widgetInfo.isSubscribedToDashboardFilters) {
                let udfColumns: CustomColDef[] = [];
                if (widgetInfo.currentVisualization === 'ADVANCED_GRID') {
                    udfColumns = widgetInfo.currentVisualizationConfig?.configs?.values
                        .filter((column) => this.exportService.isUserDefinedFieldColumn(column.name))
                        .map((column) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            return { colId: column.name, userDefinedFieldType: column.datatype as UserDefinedFieldType } as any;
                        }) ?? [];
                }
                let data = this.getWidgetData('widget', widgetInfo.id, udfColumns);
                const columns = this.getColumns(widgetInfo, isGrid, widgetColumns, metadata);
                data = this.addDataToCompareColumns(widgetInfo, columns, data, exportData);
                const widgets = this.getWidgets(widgetInfo, columns, exportData ?? {}, dateTypeOverrides);
                if (this.exportService.shouldIncludeCrosstalkKeys(widgetInfo)) {
                    data = this.addCrosstalkKeysData(widgetInfo, data);
                    const id = widgetInfo.namedQueryId ?? widgetInfo.datasetDefinition.id!;
                    const crosstalkFields = this.crosstalkDataService.getCrosstalkSchemaFields(id);
                    exportDatasetInfos.push({ metadata: metadata[widgetInfo.id], widgets, data, summary: {}, crosstalkFields });
                } else {
                    exportDatasetInfos.push({ metadata: metadata[widgetInfo.id], widgets, data, summary: {} });
                }
                this.sequenceNo += 1;
            }
        });
        return exportDatasetInfos;
    }

    setExportInfo(): void {
        this.currentDashboard = this.managerService.getWorkspace();
        this.exportInfo = {
            dashboardName: this.currentDashboard?.getExtraParameters().name,
            isExportInitialized: false,
            exportFormat: this.exportFormat,
        };
    }

    sendNoWidgetMessage(): void {
        if (this.exportInfo) {
            this.exportInfo.message = 'No widgets available for export.';
        }
        this.exportOptionsClose.emit(this.exportInfo);
    }

    exportData(): void {
        const exportType = this.isFullExport ? ExportType.FULL : ExportType.FILTERED;
        this.replaceSpecialCharacters(this.requestParam!);
        this.moveConversationIdAsLastColumn();
        const datasets = this.requestParam!.dataSet.map((dataset) => {
            if (dataset.crosstalkFields) {
                return setExportDatasetInfoForExportWithCrosstalkKeys(dataset);
            }
            return dataset;
        });
        this.dashboardService.exportDashboard(
            this.requestParam!.dashboardName,
            this.requestParam!.dashboardId,
            datasets,
            this.exportFormat!,
            this.exportFormat !== 'PDF' ? exportType : '');
        this.exportInfo!.isExportInitialized = true;
        this.exportInfo!.message = `${this.requestParam!.dashboardName}, ${this.exportFormat} has been initiated.`;
        this.exportOptionsClose.emit(this.exportInfo);
        if (this.exportFormat === 'PDF') {
            this.exportDashboardToPDF();
        }
    }

    onSelectChange(item: ExportWidgetInfo, isChecked: boolean): void {
        const info = this.exportWidgetInfos?.find((widget) => widget.id === item.id);
        if (info) {
            info.isChecked = isChecked;
        }
        this.selectedWidgetCount = this.exportWidgetInfos.filter((widget) => widget.isChecked).length;
        this.selectAll.isChecked = this.selectedWidgetCount === this.exportWidgetInfos.length;
    }

    onSelectAllChange(isChecked = false): void {
        this.exportWidgetInfos.forEach((widget) => { widget.isChecked = isChecked; });
        this.selectedWidgetCount = isChecked ? this.exportWidgetInfos.length : 0;
    }

    exportDashboardToPDF(): void {
        const dashboardName = this.managerService.getWorkspace()?.name;
        this.dashboardPdfExportService.exportDashboardToPdf(`${dashboardName}_${DdvDate.today.toDDMMYYYYFormat()}`, this.theme);
    }

    private getParamDatasets(
        widgetsMetadata: { [widgetId: number | string]: MetadataLookup },
        isGrid: boolean,
        exportData?: Record<string, ExportableData>,
    ): ExportDatasetInfo[] {
        // this mutates the parameter widgetsMetadata which is passed in as either this.gridsMetadata or this.nonGridsMetadata
        // additionally, at this point, this code works no matter which of the types for widgetsMetadata is passed in,
        // however, this.setDisplayName definitely expects the { [widgetId: number]: MetadataLookup } type, so we're going to force
        Object.values(widgetsMetadata ?? {}).forEach((metadata: MetadataLookup | FieldMetadata) => {
            Object.entries(metadata).forEach(([key, value]) => {
                value.name = key;
            });
        });

        if (exportData) {
            Object.keys(exportData).forEach((key) => {
                this.setDisplayName(key, exportData[key], widgetsMetadata);
            });
        }

        return [
            ...this.getSubscribedWidgetDatasets(widgetsMetadata, isGrid, exportData),
            ...this.getUnsubscribedWidgetDatasets(widgetsMetadata, isGrid, exportData),
        ];
    }

    private getGridsFullExportColumns(exportData: Record<string, ExportableData>): { widgetId: string, columns: string[] | undefined }[] {
        return Object.keys(exportData).map((fd) => {
            const exportDataColumns = exportData[fd].data.length ? Object.keys(exportData[fd].data[0]) : [];
            let gridColumns: (string | undefined)[];
            let columns: string[] | undefined;
            if (exportDataColumns.length) {
                const widgetInfo = this.gridsSelectedWidgetsInfos.find((widget) => widget.id?.toString() === fd.toString());
                gridColumns = widgetInfo?.currentVisualizationConfig?.configs?.values.map((value) => value.value) ?? [];
                // keep the column order saved in the grid configuration
                columns = exportDataColumns.filter((column) => gridColumns.includes(column));

                gridColumns.forEach((item, ind) => {
                    if (columns![ind] !== item) {
                        const currIndex = columns!.findIndex((col) => col === item);
                        if (currIndex !== -1) {
                            columns!.splice(ind, 0, columns!.splice(currIndex, 1)[0]);
                        }
                    }
                });
            }
            return {
                widgetId: fd,
                columns,
            };
        });
    }

    private exportFullDashboard(exportData: Record<string, ExportableData>): void {
        if (Object.keys(exportData).length !== this.selectedWidgetInfos.length) {
            return;
        }

        let gridsDataset;
        let nonGridsDataset;

        if (this.gridsSelectedWidgetsInfos.length) {
            const exportGridData: Record<string, ExportableData> = {};
            const gridIds = this.gridsSelectedWidgetsInfos.map((gridWidget) => gridWidget.id.toString());
            const exportDataKeys = Object.keys(exportData).filter((key) => gridIds.indexOf(key) !== -1);
            exportDataKeys.forEach((key) => {
                exportGridData[key] = exportData[key];
            });

            this.gridsFullExportColumns = this.getGridsFullExportColumns(exportGridData);
            gridsDataset = this.getParamDatasets(
                this.gridsMetadata as unknown as { [widgetId: number | string]: MetadataLookup },  // see comment in getParamDatasets
                true,
                exportData);
            this.requestParam?.dataSet.push(...gridsDataset);
        }

        if (this.nonGridsSelectedWidgetsInfos.length) {
            nonGridsDataset = this.getParamDatasets(
                this.nonGridsMetadata as unknown as { [widgetId: number | string]: MetadataLookup },   // see comment in getParamDatasets
                false,
                exportData);
            this.requestParam?.dataSet.push(...nonGridsDataset);
        }

        this.exportData();
        this.filteredDataService.clearFullData({});
    }

    private exportFilteredDashboard(exportData: Record<string, ExportableData>): void {
        if (Object.keys(exportData).length !== this.selectedWidgetCount) {
            return;
        }

        this.requestParam = this.getExportRequestParams(exportData);
        this.exportData();
        this.filteredDataService.clearFilteredData({});
    }

    private getExportRequestParams(exportData: Record<string, ExportableData> | undefined): ExportRequestParameter {
        const requestParam: ExportRequestParameter = {
            dashboardName: this.currentDashboard?.getExtraParameters().name,
            dashboardId: this.currentDashboard?.id.toString() ?? '',
            dataSet: [],
        };

        if (!exportData && this.exportFormat === 'PDF') {
            return requestParam;
        }

        requestParam.dataSet = Object.entries(exportData ?? {}).map(([datasetId, exportableDatum]) => {
            let columns = exportableDatum.data.length ? Object.keys(exportableDatum.data[0]) : [];
            if (!this.includeCrosstalkKeys) {
                columns = columns.filter((column) => column !== 'conversationId');
            } else {
                columns = columns.filter((column) => column !== 'Conversation Id');
            }
            if (this.exportFormat === 'CSV' && columns.length) {
                columns = this.exportService.addDateColumnsForCSVExport(exportableDatum, datasetId, columns);

                columns = columns.map((column) => {
                    let newColumn: string;
                    if (column.includes('__Grouper__')) {
                        newColumn = column.substring(0, column.indexOf('__Grouper__') - 1);
                    } else {
                        newColumn = column;
                    }
                    return newColumn;
                });
            }

            const dateTypeOverrides: Record<string, string | undefined> = {};
            let currentWidget: ExportWidgetInfo | undefined;
            if (this.gridsSelectedWidgetsInfos) {
                currentWidget = this.gridsSelectedWidgetsInfos.find((sw) => sw.id.toString() === datasetId);
                if (currentWidget) {
                    const udfColumns: { name: string, type: UserDefinedFieldType }[] = [];
                    currentWidget.currentVisualizationConfig?.configs?.values.forEach((column) => {
                        const columnName = column.name ?? '';
                        const columnDefinition = (this.metadata as { [widgetId: number | string]: MetadataLookup })?.[currentWidget?.id ?? ''][columnName];
                        if (columns.includes(column.displayName ?? '') &&
                            columnName === columnDefinition?.name &&
                            column.displayType !== columnDefinition.displayType
                        ) {
                            dateTypeOverrides[column.displayName ?? ''] = column.displayType;
                        } else if (column.displayType === 'string') {
                            dateTypeOverrides[column.displayName ?? ''] = column.displayType;
                        }

                        if (this.exportService.isUserDefinedFieldColumn(column.name)) {
                            udfColumns.push({
                                name: (column.customName ?? column.displayName) ?? '',
                                type: column.datatype as UserDefinedFieldType,
                            });
                        }
                    });

                    if (udfColumns.length) {
                        udfColumns.forEach((udf) => {
                            const { name, type } = udf;
                            exportableDatum.data.forEach((datum) => {
                                datum[name] = this.exportService.setUserDefinedFieldExportValue(datum, name, type);
                            });
                        });
                    }
                }
            }

            const widgetNameSuffix = this.isFullExport ? ExportType.FULL : ExportType.FILTERED;
            const widgets = [{
                widgetId: datasetId.toString(),
                widgetName: `${this.sequenceNo}_${exportableDatum.widgetName}-${widgetNameSuffix}`,
                columnNames: !this.isFullExport && this.exportFormat === 'EXCEL' && exportableDatum.visibleColumns?.length ?
                    exportableDatum.visibleColumns :
                    columns,
                datasetType: exportableDatum.datasetType,
                dateFrom: exportableDatum ? this.exportService.fuzzyDateCheck(exportableDatum.startDate, true) : undefined,
                dateTo: exportableDatum ? this.exportService.fuzzyDateCheck(exportableDatum.endDate) : undefined,
                extractionDateTime: DdvDateTime.now.toString(),
                groupers: exportableDatum.groupers ?? [],
                datetypeoverrides: dateTypeOverrides,
                showSubTotal: currentWidget?.currentVisualizationConfig?.showSubTotal,
            }];

            const metadata = this.setDisplayName(
                datasetId,
                exportableDatum,
                this.metadata as { [widgetId: number | string]: MetadataLookup },
                true,
            );
            this.sequenceNo += 1;

            if (this.exportService.shouldIncludeCrosstalkKeys(currentWidget)) {
                const queryId = currentWidget?.namedQueryId ?? currentWidget?.datasetDefinition.id ?? 0;
                return {
                    metadata,
                    widgets,
                    data: exportableDatum.data,
                    summary: exportableDatum.summary,
                    crosstalkFields: this.crosstalkDataService.getCrosstalkSchemaFields(queryId),
                };
            }

            return {
                metadata,
                widgets,
                data: exportableDatum.data,
                summary: exportableDatum.summary,
            };
        });

        return requestParam;
    }

    private exportInfosToMetadataFetchContexts(infos: ExportWidgetInfo[]): BoardWidgetDefinitionIds[] {
        return infos.map((info) => new BoardWidgetDefinitionIds(
            undefined,
            info.id,
            info.namedQueryId ?? info.datasetDefinition.id,
        ));
    }

    private setDisplayName(
        widgetId: number | string,
        data: ExportableData,
        metadata: { [widgetId: number | string]: MetadataLookup },
        returnFilteredMetadata = false,
    ): MetadataLookup | undefined {
        this.selectedWidgetInfos.forEach((widget) => {
            if (widget.name === data.widgetName) {
                this.managerService.getWidgetPreferences(widget.id)?.visualizationConfigs.forEach((vizConfig) => {
                    vizConfig.configs?.values.forEach((configValue, index) => {
                        const configValueValue = configValue.value;
                        if (configValueValue === undefined) {
                            return console.error(`DashboardExportComponent.setDisplayName: vizConfig.configs.values[${index}].value is undefined`);
                        }

                        const columnDefinition = metadata[widget.id]?.[configValueValue];
                        if (columnDefinition && !columnDefinition.nameChecked) {
                            const displayName = configValue.showCustomName ? configValue.customName : configValue.displayName;
                            if (!displayName) {
                                return console.error(`DashboardExportComponent.setDisplayName: vizConfig.configs.values[${index}].value has no displayName or customName`);
                            }
                            columnDefinition.displayName = displayName;
                            columnDefinition.nameChecked = true;
                        }
                    });
                });
            }
        });

        if (metadata[widgetId]) {
            Object.keys(metadata[widgetId]).forEach((key) => {
                metadata[widgetId][key].nameChecked = false;
            });
        }

        if (returnFilteredMetadata) {
            return (this.metadata as { [widgetId: number | string]: MetadataLookup })?.[widgetId];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addDataToCompareColumns(ew: ExportWidgetInfo, columns: string[], data: WidgetData[], exportData: any): WidgetData[] {
        let updatedData = data;
        columns.forEach((column) => {
            if (column.includes(CompareColumnID.COMPARE) || column.includes(CompareColumnID.DIFF)) {
                const compareData = Object.keys(exportData).map((fd) => {
                    if (ew.id.toString() === fd) {
                        return exportData[fd].data;
                    }
                }).filter((fd) => !!fd);

                const index = (column.indexOf(CompareColumnID.COMPARE) !== -1) ?
                    column.indexOf(CompareColumnID.COMPARE) :
                    column.indexOf(CompareColumnID.DIFF);
                const compareKey = column.substring(0, index);

                updatedData = updatedData.map((dataObj) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const compareDataObj = compareData[0].find((obj: any) => obj[compareKey] === dataObj[compareKey]);
                    return compareDataObj ? { ...dataObj, [column]: compareDataObj[column] } : dataObj;
                });
            }
        });

        return updatedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getWidgetData(
        sourceType: 'dashboard' | 'widget',
        namedQueryOrWidgetId: number | string,
        userDefinedFieldColumns?: (ConfigItem | CustomColDef)[],
    ): WidgetData[] {
        const dataWrapper = this.widgetDatasource?.datasources.find((dw) => {
            return dw.uniqueKey.sourceType === sourceType && dw.uniqueKey.sourceId === namedQueryOrWidgetId;
        });

        const originalData = dataWrapper?.originalData;
        const data = dataWrapper?.data;
        const widgetData = deepClone(originalData ?? data ?? []);

        if (userDefinedFieldColumns?.length) {
            userDefinedFieldColumns.forEach((udf) => {
                const { colId, userDefinedFieldType: type } = udf;
                if (colId && type) {
                    widgetData.forEach((datum) => datum[colId] = this.exportService.setUserDefinedFieldExportValue(datum, colId, type));
                }
            });
        }

        return widgetData;
    }

    private getDataTypeOverrides(
        widgetInfo: ExportWidgetInfo,
        metadata: { [widgetId: number | string]: MetadataLookup },
    ): { [key: string]: string | undefined } {
        const widgetId = widgetInfo.id;
        if (!widgetInfo) {
            return {};
        }

        const dataTypeOverrides: Record<string, string | undefined> = {};
        widgetInfo.currentVisualizationConfig?.configs?.values.forEach((column) => {
            if (column.colId !== crosstalkCheckboxFieldId) {
                const columnName = column.name ?? '';
                const columnDefinition = metadata[widgetId][columnName];
                if (columnName === columnDefinition?.name && column.displayType !== columnDefinition.displayType) {
                    dataTypeOverrides[column.displayName ?? ''] = column.displayType;
                } else if (column.displayType === 'string') {
                    dataTypeOverrides[column.displayName ?? ''] = column.displayType;
                }
            }
        });
        return dataTypeOverrides;
    }

    private getWidgetColumns(values: ConfigItem[] | undefined): string[] {
        if (values) {
            return values.reduce((columns: string[], column: ConfigItem) => {
                if (column.value != null) {
                    columns.push(column.value);
                }
                return columns;
            }, []);
        }
        return [];
    }

    private getColumns(
        widgetInfo: ExportWidgetInfo,
        isGrid: boolean,
        widgetColumns: (string | undefined)[],
        metadata: { [widgetId: number | string]: MetadataLookup },
    ): string[] {
        const gridColumnsForWidget = this.gridsFullExportColumns?.length ?
            this.gridsFullExportColumns.find((widget) => widget.widgetId.toString() === widgetInfo.id.toString()) :
            null;
        const columns = !isGrid ?
            Object.keys(metadata[widgetInfo.id]) :
            (gridColumnsForWidget ? (gridColumnsForWidget.columns! || widgetColumns) : []);

        if (this.exportFormat === 'CSV' && columns.length) {
            if (['Recon', 'Checklists'].indexOf(widgetInfo.datasetDefinition.dataType ?? '') === -1) {
                columns.push('From Date');
            }
            columns.push(...['To Date', 'Time of Extract']);
        }

        if (this.exportService.shouldIncludeCrosstalkKeys(widgetInfo)) {
            const id = widgetInfo.namedQueryId ?? widgetInfo.datasetDefinition.id!;
            this.crosstalkDataService.getCrosstalkSchemaFields(id)?.forEach((field) => {
                columns.push(field.name);
            });
        }
        return columns;
    }

    private getWidgets(
        widgetInfo: ExportWidgetInfo,
        columns: string[],
        exportData: Record<string, ExportableData>,
        dataTypeOverrides: { [key: string]: string | undefined },
    ): ExportDatasetWidgetInfo[] {
        return [{
            widgetId: widgetInfo.id.toString(),
            widgetName: `${this.sequenceNo}_${widgetInfo.name}-${ExportType.FULL}`,
            columnNames: columns,
            datasetType: widgetInfo.datasetDefinition.dataType,
            extractionDateTime: DdvDateTime.now.toString(),
            dateFrom: exportData[widgetInfo.id] ?
                this.exportService.fuzzyDateCheck(exportData[widgetInfo.id].startDate, true) :
                undefined,
            dateTo: exportData[widgetInfo.id] ?
                this.exportService.fuzzyDateCheck(exportData[widgetInfo.id].endDate) :
                undefined,
            datetypeoverrides: dataTypeOverrides,
        }];
    }

    private replaceSpecialCharacters(requestParam: ExportRequestParameter): void {
        requestParam.dashboardName = requestParam.dashboardName.replace(SPECIAL_CHARS_REGEX, '_');
        requestParam.dataSet.forEach((d) => {
            d.widgets.forEach((w) => {
                w.widgetName = w.widgetName.replace(SPECIAL_CHARS_REGEX, '_');
            });
        });
    }

    private addCrosstalkKeysData(sw: ExportWidgetInfo, data: WidgetData[]): WidgetData[] {
        const id = sw.namedQueryId ?? sw.datasetDefinition.id!;
        const crosstalkFields = this.crosstalkDataService.getCrosstalkSchemaFields(id) ?? [];
        data.forEach((row) => {
            crosstalkFields.forEach((field) => {
                const valueToString = row[field.displayName]?.toString();
                row[field.name] = valueToString ? valueToString : '';
            });
        });

        return data;
    }

    private moveConversationIdAsLastColumn(): void {
        this.requestParam?.dataSet.forEach((dataset) => {
            if (dataset.crosstalkFields) {
                this.requestParam?.dataSet.forEach((exportInfo) => {
                    exportInfo.widgets.forEach((widget) => {
                        widget.columnNames = widget.columnNames.filter((column) => column !== 'conversationId');
                        widget.columnNames.push('conversationId');
                    });
                    exportInfo.crosstalkFields = exportInfo.crosstalkFields?.filter((field) => field.name !== 'conversationId');
                    exportInfo.crosstalkFields?.push({ name: 'conversationId', displayName: 'conversationId' });
                });
            }
        });
    }
}

function setExportDatasetInfoForExportWithCrosstalkKeys(dataset: ExportDatasetInfo): ExportDatasetInfo {
    if (dataset.widgets && dataset.metadata) {
        const datasetMetadata = dataset.metadata;
        Object.keys(datasetMetadata).forEach((key) => {
            dataset.widgets.forEach((widget) => {
                const widgetGroupers = widget.groupers ?? [];
                widget.groupers = widgetGroupers.map((grouperName) => {
                    if (datasetMetadata[key].displayName === grouperName) {
                        return datasetMetadata[key].name!;
                    }
                    return grouperName;
                });

                widget.columnNames = widget.columnNames.map((columnName) => {
                    if (columnName.endsWith('_crosstalk_key')) {
                        return columnName;
                    }
                    return datasetMetadata[key].displayName === columnName ? datasetMetadata[key].name! : columnName;
                });
            });

            if (dataset.data) {
                dataset.data = dataset.data.map((data) => {
                    const newData = Object.keys(data).reduce((datum, dataKey) => {
                        if (datasetMetadata[key].displayName === dataKey) {
                            datum[datasetMetadata[key].name!] = data[dataKey];
                        } else {
                            datum[dataKey] = data[dataKey];
                        }
                        return datum;
                    }, {} as Record<string, unknown>);

                    newData.conversationId = data.conversationId ?? '';

                    return newData;
                });
            }
            datasetMetadata[key].displayName = datasetMetadata[key].name;
        });
        dataset.metadata = datasetMetadata;
    }

    return dataset;
}
