import { UserGroupType } from './user-group-type';
import { UserPermission } from './user-permission';

export class UserPreferences {
    id: number;
    username: string;
    clientCode: string;
    fundsCodes: string[];
    clientsCodes: string[];
    autoDismissErrors: boolean;
    startDate?: string;
    endDate?: string;
    version: number;
    fundNamesSource: string;
    widgetFinderLastTab: string;
    isFullExport?: boolean;
    showAcknowledged?: boolean;
    clients?: { clientCode: string }[];
    funds?: { fundCode: string }[];

    constructor(init?: Partial<UserPreferences>) {
        this.id = init?.id ?? Number.MIN_VALUE;
        this.username = init?.username ?? '';
        this.clientCode = init?.clientCode ?? '';
        this.fundsCodes = init?.fundsCodes ?? [];
        this.clientsCodes = init?.clientsCodes ?? [];
        this.autoDismissErrors = !!init?.autoDismissErrors;
        this.startDate = init?.startDate;
        this.endDate = init?.endDate;
        this.version = init?.version ?? Number.MIN_VALUE;
        this.fundNamesSource = init?.fundNamesSource ?? '';  // this is a non-sensical default that was added because the types were difficult to fix
        this.widgetFinderLastTab = init?.widgetFinderLastTab ?? 'Datatype';
        this.isFullExport = init?.isFullExport;
        this.showAcknowledged = init?.showAcknowledged;
        this.clients = init?.clients;
        this.funds = init?.funds;

        this.fundsCodes = this.fundsCodes || [];
        this.clientsCodes = this.clientsCodes || [];
        this.showAcknowledged = this.showAcknowledged != null ? this.showAcknowledged : false;
    }
}

export interface UserWithType {
    name: string;
    type: UserGroupType;
    internal?: boolean;
}

export interface User extends UserPermission {
    selected?: boolean;
    displayPrivilege?: string;
}

